// SettlementParticipantList.js

import React from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  CircularProgress,
  alpha,
  styled,
} from "@mui/material";
import {
  AccountBalanceWallet as WalletIcon,
  Receipt as ReceiptIcon,
  LocalAtm as MoneyIcon,
} from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";
import ItemDetails from "./ItemDetails";
import { useAuth } from "../../contexts/AuthContext";
import { SUPPORTED_CURRENCIES } from "../../utils/currencyUtils";

const ParticipantSection = styled(Box)({
  marginBottom: 24,
});

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 1),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const ParticipantCard = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
  },
}));

const ShareDetail = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  padding: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.background.default, 0.3),
  borderRadius: theme.shape.borderRadius,
}));

const StatusChip = styled(Box)(({ theme, isSettled }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  fontSize: "0.75rem",
  fontWeight: 500,
  backgroundColor: isSettled
    ? alpha(theme.palette.success.main, 0.1)
    : alpha(theme.palette.warning.main, 0.1),
  color: isSettled ? theme.palette.success.main : theme.palette.warning.main,
}));

const SettleButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
  },
}));

const ShareAmountDisplay = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  padding: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1),
  marginLeft: -theme.spacing(2), // Extend to left edge
  marginRight: -theme.spacing(2), // Extend to right edge
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const SettlementParticipantList = ({
  participants,
  paidBy,
  currentUserId,
  loading,
  loadingParticipantId,
  onSettleShare,
  totalAmount,
  splitType,
  items = [],
}) => {
  const { user, userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || 'USD';
  
  const formatCurrency = (value) => {
    const num = parseFloat(value);
    if (isNaN(num))
      return new Intl.NumberFormat(
        SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
        {
          style: "currency",
          currency: currencyCode || "USD",
        }
      ).format(0);

    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
      }
    ).format(num);
  };

  const renderParticipantShare = (participant) => {
    switch (splitType) {
      case "percentage":
        return (
          <ShareDetail>
            <ShareAmountDisplay>
              <MoneyIcon fontSize="small" color="primary" />
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" fontWeight={500}>
                  {participant.splitValue?.percentage}% of total
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Your share of {formatCurrency(totalAmount.toFixed(2))}
                </Typography>
              </Box>
              <Typography
                variant="subtitle1"
                color="primary.main"
                fontWeight={600}
              >
                {formatCurrency(Math.abs(participant.share.original).toFixed(2))}
              </Typography>
            </ShareAmountDisplay>
          </ShareDetail>
        );

      case "shares":
        return (
          <ShareDetail>
            <ShareAmountDisplay>
              <MoneyIcon fontSize="small" color="primary" />
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" fontWeight={500}>
                  {participant.splitValue?.shares} shares
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Out of total shares
                </Typography>
              </Box>
              <Typography
                variant="subtitle1"
                color="primary.main"
                fontWeight={600}
              >
                {formatCurrency(Math.abs(participant.share.original).toFixed(2))}
              </Typography>
            </ShareAmountDisplay>
          </ShareDetail>
        );

      case "items":
        return (
          <Box sx={{ mt: 2 }}>
            <ItemDetails
              items={items}
              participantId={participant.userId}
              allParticipants={participants}
            />
            <ShareAmountDisplay>
              <MoneyIcon fontSize="small" color="primary" />
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" fontWeight={500}>
                  Total Share
                </Typography>
              </Box>
              <Typography
                variant="subtitle1"
                color="primary.main"
                fontWeight={600}
              >
                {formatCurrency(Math.abs(participant.share.original).toFixed(2))}
              </Typography>
            </ShareAmountDisplay>
          </Box>
        );

      default: // Equal split
        return (
          <ShareDetail>
            <ShareAmountDisplay>
              <MoneyIcon fontSize="small" color="primary" />
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" fontWeight={500}>
                  Equal split ({(100 / participants.length).toFixed(1)}%)
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Split equally between {participants.length} people
                </Typography>
              </Box>
              <Typography
                variant="subtitle1"
                color="primary.main"
                fontWeight={600}
              >
                {formatCurrency(Math.abs(participant.share.original).toFixed(2))}
              </Typography>
            </ShareAmountDisplay>
          </ShareDetail>
        );
    }
  };

  return (
    <ParticipantSection>
      <SectionTitle variant="subtitle2">
        <ReceiptIcon fontSize="small" />
        Payment Distribution
      </SectionTitle>

      <AnimatePresence mode="wait">
        {participants.map((participant, index) => {
          const isPayer = participant.userId === paidBy;
          const isCurrentUser = participant.userId === currentUserId;
          const isSettled = participant.settled || isPayer;

          return (
            <ParticipantCard>
              <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                <Avatar
                  src={participant.photoURL || "/api/placeholder/40/40"}
                  sx={{ width: 40, height: 40 }}
                />
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center", // Center align the name and status
                      width: "100%",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle1" fontWeight={500}>
                      {participant.displayName || participant.email}
                      {isCurrentUser && (
                        <Typography
                          component="span"
                          variant="caption"
                          color="primary.main"
                          sx={{ ml: 1 }}
                        >
                          (You)
                        </Typography>
                      )}
                    </Typography>

                    <StatusChip isSettled={isSettled}>
                      {isPayer ? (
                        "Paid Full Amount"
                      ) : isSettled ? (
                        <>
                          Paid
                        </>
                      ) : (
                        "Pending"
                      )}
                    </StatusChip>
                  </Box>

                  {/* Split type label now starts from left */}

                  {/* Share details content (the card) now extends edge to edge */}
                  <Box
                    sx={{
                      mx: -2, // Extend beyond parent padding
                      px: 2, // Add internal padding
                      mt: 1,
                    }}
                  >
                    {renderParticipantShare(participant)}
                  </Box>

                  {/* Settlement button also edge to edge */}
                  {!isPayer && !participant.settled && isCurrentUser && (
                    <Box sx={{ mx: -2, px: 2, mt: 2 }}>
                      <SettleButton
                        variant="contained"
                        fullWidth
                        onClick={() => onSettleShare(participant)}
                        disabled={
                          loading && loadingParticipantId === participant.userId
                        }
                        startIcon={
                          loading &&
                          loadingParticipantId === participant.userId ? (
                            <CircularProgress size={16} />
                          ) : (
                            <WalletIcon />
                          )
                        }
                      >
                        {loading && loadingParticipantId === participant.userId
                          ? "Processing..."
                          : "Settle Payment"}
                      </SettleButton>
                    </Box>
                  )}
                </Box>
              </Box>
            </ParticipantCard>
          );
        })}
      </AnimatePresence>
    </ParticipantSection>
  );
};

export default SettlementParticipantList;
