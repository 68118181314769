export const getTokenFromStorage = () => {
  return localStorage.getItem("firebaseToken");
};

export const setTokenInStorage = (token) => {
  if (token) {
    localStorage.setItem("firebaseToken", token);
  } else {
    localStorage.removeItem("firebaseToken");
  }
};

export const removeTokenFromStorage = () => {
  localStorage.removeItem("firebaseToken");
};
