import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  Box,
  Typography,
  useTheme,
  styled,
  useMediaQuery,
  Button,
  alpha,
  Tooltip,
} from "@mui/material";
import {
  Add as AddIcon,
  Receipt as ReceiptIcon,
  Group as GroupIcon,
} from "@mui/icons-material";
import BillCard from "./BillCard";
import BillsLoader from "./BillsLoader";

const PageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1200px",
  margin: "0 auto",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(12),
  },
}));

const BillTypeNavigation = styled(Box)(({ theme }) => ({
  // Web styles (default)
  background: alpha(theme.palette.background.paper, 0.4),
  backdropFilter: "blur(8px)",
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(1),
  display: "inline-flex",
  gap: theme.spacing(1),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  marginBottom: theme.spacing(3),

  // Mobile styles
  [theme.breakpoints.down("sm")]: {
    background: alpha(theme.palette.background.paper, 0.3),
    backdropFilter: "blur(8px)",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    gap: 0,
    border: "none",
    marginBottom: theme.spacing(2),
  },
}));

const BillTypeButton = styled(Button)(({ theme, active }) => ({
  // Web styles (default)
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.shape.borderRadius * 1.5,
  backgroundColor: active ? theme.palette.primary.main : "transparent",
  color: active
    ? theme.palette.common.black
    : alpha(theme.palette.common.white, 0.7),
  fontWeight: 600,
  transition: "all 0.2s ease-in-out",
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),

  "&:hover": {
    backgroundColor: active
      ? theme.palette.primary.main
      : alpha(theme.palette.common.white, 0.1),
  },

  // Mobile styles
  [theme.breakpoints.down("sm")]: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.5),
    padding: theme.spacing(1),
    backgroundColor: "transparent",
    borderRadius: 0,
    color: active
      ? theme.palette.primary.main
      : alpha(theme.palette.common.white, 0.7),

    "& .MuiSvgIcon-root": {
      fontSize: "1.5rem",
      marginBottom: theme.spacing(0.5),
    },

    "& .button-label": {
      fontSize: "0.875rem",
      fontWeight: active ? 600 : 400,
    },

    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.05),
    },
  },
}));

const StatusTabs = styled(Box)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  display: "flex",
  gap: theme.spacing(0.5),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
}));

const StatusTab = styled(Button)(({ theme, active }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: active
    ? alpha(theme.palette.secondary.main, 0.2)
    : "transparent",
  color: active
    ? theme.palette.secondary.main
    : alpha(theme.palette.common.white, 0.5),
  fontSize: "0.875rem",
  fontWeight: 500,
  textTransform: "none",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0.75, 1.5),
    fontSize: "0.75rem",
  },
  "&:hover": {
    backgroundColor: active
      ? alpha(theme.palette.secondary.main, 0.25)
      : alpha(theme.palette.common.white, 0.05),
  },
}));

const BillsGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& > *": {
      width: "100%",
    },
  },
}));

const AddBillButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main, // Purple color
  color: theme.palette.common.white,
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.shape.borderRadius * 1.5,
  boxShadow: `0 8px 16px ${alpha(theme.palette.secondary.main, 0.2)}`,
  textTransform: "none",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.main, 0.9),
  },
}));

const AddBillFAB = styled(Button)(({ theme }) => ({
  position: "fixed",
  bottom: 80,
  right: theme.spacing(2),
  borderRadius: "50%",
  width: 56,
  height: 56,
  minWidth: "unset",
  padding: 0,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.15)}`,
  zIndex: 1000,
  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.main, 0.9),
  },
}));

const EmptyStateContainer = styled(motion.div)(({ theme }) => ({
  background: `linear-gradient(145deg, ${alpha(
    theme.palette.background.paper,
    0.1
  )} 0%, ${alpha(theme.palette.background.paper, 0.2)} 100%)`,
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(6),
  textAlign: "center",
  position: "relative",
  overflow: "hidden",
}));

const EmptyStateIcon = styled(Box)(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: "50%",
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
  marginBottom: theme.spacing(3),
  "& svg": {
    fontSize: 40,
    color: theme.palette.primary.main,
  },
}));

const BillsList = ({ bills, loading, error, onBillUpdate }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentTab, setCurrentTab] = useState("pending");
  const [billType, setBillType] = useState("personal");

  const handleBillUpdate = useCallback(
    (updatedBills) => {
      if (onBillUpdate) {
        onBillUpdate(updatedBills);
      }
    },
    [onBillUpdate]
  );

  const handleAddBillClick = () => {
    navigate("/add-bill");
  };

  const renderEmptyState = () => {
    const emptyStateText = {
      title: `No ${currentTab === "pending" ? "Pending" : "Completed"} ${
        billType === "personal" ? "Personal" : "Group"
      } Bills`,
      description: `You don't have any ${
        currentTab === "pending" ? "pending" : "completed"
      } ${billType === "personal" ? "personal" : "group"} bills.`,
    };

    return (
      <EmptyStateContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <EmptyStateIcon>
          {billType === "personal" ? <ReceiptIcon /> : <GroupIcon />}
        </EmptyStateIcon>
        <Typography variant="h5" color="primary.main" gutterBottom>
          {emptyStateText.title}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ maxWidth: 300, margin: "0 auto" }}
        >
          {emptyStateText.description}
        </Typography>
      </EmptyStateContainer>
    );
  };

  const filteredBills = React.useMemo(() => {
    if (!bills) return [];

    return bills.filter((bill) => {
      const isPending =
        currentTab === "pending"
          ? bill.status === "pending" ||
            bill.status === "active" ||
            !bill.status
          : bill.status === "completed";
      const isPersonalBill = !bill.groupId;
      const matchesType =
        billType === "personal" ? isPersonalBill : !isPersonalBill;
      const isNotDeleted = bill.status !== "deleted";
      return isPending && matchesType && isNotDeleted;
    });
  }, [bills, currentTab, billType]);

  return (
    <PageContainer>
      <HeaderSection>
        <BillTypeNavigation>
          <BillTypeButton
            active={billType === "personal"}
            onClick={() => setBillType("personal")}
          >
            <ReceiptIcon />
            <Typography className="button-label">
              {isMobile ? "Personal" : "Personal Bills"}
            </Typography>
          </BillTypeButton>
          <BillTypeButton
            active={billType === "group"}
            onClick={() => setBillType("group")}
          >
            <GroupIcon />
            <Typography className="button-label">
              {isMobile ? "Group" : "Group Bills"}
            </Typography>
          </BillTypeButton>
        </BillTypeNavigation>

        {!isMobile && (
          <AddBillButton startIcon={<AddIcon />} onClick={handleAddBillClick}>
            Add Bill
          </AddBillButton>
        )}
      </HeaderSection>

      <StatusTabs>
        <StatusTab
          active={currentTab === "pending"}
          onClick={() => setCurrentTab("pending")}
        >
          Pending
        </StatusTab>
        <StatusTab
          active={currentTab === "completed"}
          onClick={() => setCurrentTab("completed")}
        >
          Completed
        </StatusTab>
      </StatusTabs>

      <AnimatePresence mode="wait">
        {(bills?.length === 0 || filteredBills.length === 0) && !loading ? (
          renderEmptyState()
        ) : (
          <BillsGrid>
            {filteredBills.map((bill) => (
              <BillCard
                key={bill.id}
                bill={bill}
                onBillUpdate={handleBillUpdate}
              />
            ))}
          </BillsGrid>
        )}
      </AnimatePresence>

      {loading && <BillsLoader />}

      {isMobile && (
        <Tooltip title="Add New Bill" placement="left">
          <AddBillFAB onClick={handleAddBillClick} aria-label="add bill">
            <AddIcon />
          </AddBillFAB>
        </Tooltip>
      )}
    </PageContainer>
  );
};

export default BillsList;
