import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Grid,
  Chip,
  Avatar,
  Paper,
  alpha,
  styled,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";

const ItemCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: alpha(theme.palette.background.default, 0.6),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    "& fieldset": {
      border: "none",
    },
  },
}));

const ParticipantChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
}));

const ParticipantSelector = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  padding: theme.spacing(1),
  border: `1px dashed ${alpha(theme.palette.primary.main, 0.3)}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.2),
}));

const SummaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "0.875rem",
  fontWeight: 500,
  marginTop: theme.spacing(1),
}));

const ItemSplitInput = ({
  billParticipants,
  items = [],
  onAddItem,
  onRemoveItem,
}) => {
  const { userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || "USD";
  const [currentItem, setCurrentItem] = useState({
    name: "",
    quantity: "1", // Default to 1
    totalAmount: "",
    participants: [],
  });

  const formatCurrency = (value) => {
    if (typeof value !== "number" || isNaN(value)) {
      value = 0;
    }
    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    ).format(value);
  };

  const handleAddItem = () => {
    if (
      !currentItem.name ||
      !currentItem.totalAmount ||
      !currentItem.quantity ||
      currentItem.participants.length === 0
    ) {
      return;
    }

    const totalAmount = parseFloat(currentItem.totalAmount);
    const quantity = parseInt(currentItem.quantity);

    if (
      isNaN(totalAmount) ||
      totalAmount <= 0 ||
      isNaN(quantity) ||
      quantity <= 0
    ) {
      return;
    }

    // Calculate per person share for this item
    const perPersonShare = totalAmount / currentItem.participants.length;

    const newItem = {
      id: Date.now().toString(),
      name: currentItem.name,
      quantity: quantity,
      totalAmount: totalAmount,
      participants: currentItem.participants,
      perPersonShare: parseFloat(perPersonShare.toFixed(2)),
      pricePerItem: parseFloat((totalAmount / quantity).toFixed(2)),
    };

    onAddItem(newItem);
    setCurrentItem({
      name: "",
      quantity: "1", // Reset to default 1
      totalAmount: "",
      participants: [],
    });
  };

  const toggleParticipant = (participantId) => {
    setCurrentItem((prev) => ({
      ...prev,
      participants: prev.participants.includes(participantId)
        ? prev.participants.filter((id) => id !== participantId)
        : [...prev.participants, participantId],
    }));
  };

  const calculatePerPersonShare = (totalAmount, participants) => {
    if (!participants || participants.length === 0) return "0.00";
    return (totalAmount / participants.length).toFixed(2);
  };

  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        Add Items
      </Typography>

      <ItemCard elevation={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledInput
              fullWidth
              label="Item Name"
              value={currentItem.name}
              onChange={(e) =>
                setCurrentItem((prev) => ({ ...prev, name: e.target.value }))
              }
              placeholder="Enter item name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledInput
              fullWidth
              type="number"
              label="Quantity"
              value={currentItem.quantity}
              onChange={(e) =>
                setCurrentItem((prev) => ({
                  ...prev,
                  quantity: e.target.value,
                }))
              }
              placeholder="1"
              inputProps={{ min: "1", step: "1" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledInput
              fullWidth
              type="number"
              label="Total Amount"
              value={currentItem.totalAmount}
              onChange={(e) =>
                setCurrentItem((prev) => ({
                  ...prev,
                  totalAmount: e.target.value,
                }))
              }
              placeholder="0.00"
              inputProps={{ step: "0.01", min: "0" }}
            />
          </Grid>
        </Grid>

        {currentItem.totalAmount && currentItem.quantity && (
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ mt: 1, display: "block" }}
          >
            Price per item:
            {formatCurrency(
              parseFloat(currentItem.totalAmount) /
                parseInt(currentItem.quantity)
            )}
          </Typography>
        )}

        <ParticipantSelector>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ width: "100%", mb: 1 }}
          >
            Select who's splitting this item:
          </Typography>
          {billParticipants.map((participant) => (
            <ParticipantChip
              key={participant.userId}
              avatar={
                <Avatar
                  src={participant.photoURL || "/api/placeholder/32/32"}
                />
              }
              label={participant.displayName || participant.email}
              onClick={() => toggleParticipant(participant.userId)}
              variant={
                currentItem.participants.includes(participant.userId)
                  ? "filled"
                  : "outlined"
              }
            />
          ))}
        </ParticipantSelector>

        {currentItem.totalAmount && currentItem.participants.length > 0 && (
          <Box
            sx={{
              mt: 2,
              p: 2,
              bgcolor: alpha("rgba(0,0,0,0.1)", 0.1),
              borderRadius: 1,
            }}
          >
            <Typography variant="caption" color="text.secondary">
              Cost Breakdown:
            </Typography>
            <SummaryText>
              {formatCurrency(currentItem.totalAmount)} ÷{" "}
              {currentItem.participants.length} people =
              {calculatePerPersonShare(
                parseFloat(currentItem.totalAmount),
                currentItem.participants
              )}{" "}
              per person
            </SummaryText>
          </Box>
        )}

        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={handleAddItem}
            startIcon={<AddIcon />}
            disabled={
              !currentItem.name ||
              !currentItem.totalAmount ||
              !currentItem.quantity ||
              currentItem.participants.length === 0
            }
          >
            Add Item
          </Button>
        </Box>
      </ItemCard>

      {items.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Added Items
          </Typography>
          {items.map((item) => (
            <ItemCard key={item.id} elevation={0}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Box>
                  <Typography variant="subtitle1">
                    {item.name} × {item.quantity}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {formatCurrency(item.pricePerItem)} per item
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary.main"
                    sx={{ mt: 0.5 }}
                  >
                    Total: {formatCurrency(item.totalAmount)}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="secondary.main"
                    sx={{ mt: 0.5 }}
                  >
                    Split between {item.participants.length} people: 
                    {formatCurrency(item.perPersonShare)} each
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ display: "block", mb: 0.5 }}
                    >
                      People splitting this item:
                    </Typography>
                    {item.participants.map((participantId) => {
                      const participant = billParticipants.find(
                        (p) => p.userId === participantId
                      );
                      return (
                        <ParticipantChip
                          key={participantId}
                          size="small"
                          avatar={
                            <Avatar
                              src={
                                participant?.photoURL ||
                                "/api/placeholder/32/32"
                              }
                              sx={{ width: 24, height: 24 }}
                            />
                          }
                          label={participant?.displayName || participant?.email}
                        />
                      );
                    })}
                  </Box>
                </Box>
                <IconButton
                  onClick={() => onRemoveItem(item.id)}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </ItemCard>
          ))}

          <Box
            sx={{
              mt: 2,
              p: 2,
              bgcolor: alpha("rgba(0,0,0,0.1)", 0.1),
              borderRadius: 1,
            }}
          >
            <Typography variant="subtitle2" color="primary.main">
              Total Bill Amount: 
              {formatCurrency(
                items.reduce((sum, item) => sum + item.totalAmount, 0)
              )}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ItemSplitInput;
