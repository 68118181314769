import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import HeaderNav from './HeaderNav';
import BottomNav from './BottomNav';

const ResponsiveNav = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      {isDesktop ? <HeaderNav /> : <BottomNav />}
    </>
  );
};

export default ResponsiveNav;