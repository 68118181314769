import React, { useEffect, useState } from 'react';
import { Box, Typography, Tooltip, styled, alpha } from '@mui/material';
import { subscribeToUserPresence } from '../backend-services/firebase';
import { formatDistanceToNow } from 'date-fns';

const OnlineDot = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const OfflineDot = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.text.secondary, 0.5),
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserPresence = ({ userId, showLabel = false }) => {
  const [presenceState, setPresenceState] = useState({
    isOnline: false,
    lastSeen: null
  });

  useEffect(() => {
    if (!userId) return;

    const unsubscribe = subscribeToUserPresence(userId, (presence) => {
      setPresenceState(presence);
    });

    return () => unsubscribe();
  }, [userId]);

  const getLastSeenText = () => {
    if (presenceState.isOnline) return 'Online';
    if (!presenceState.lastSeen) return 'Offline';
    return `Last seen ${formatDistanceToNow(presenceState.lastSeen, { addSuffix: true })}`;
  };

  return (
    <Tooltip title={getLastSeenText()}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {presenceState.isOnline ? <OnlineDot /> : <OfflineDot />}
        {showLabel && (
          <Typography
            variant="caption"
            color={presenceState.isOnline ? 'success.main' : 'text.secondary'}
          >
            {getLastSeenText()}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};

export default UserPresence;