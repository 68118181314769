import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./firebase";
import { getBillsByGroup } from "./firebase";

/**
 * Calculates optimized settlements for a group based on their bills
 * @param {string} groupId - The ID of the group
 * @returns {Promise<Array>} - Array of settlement objects
 */
export const calculateGroupSettlements = async (groupId) => {
  try {
    // Fetch all relevant data
    const bills = await getBillsByGroup(groupId);
    const groupDoc = await getDoc(doc(db, "groups", groupId));

    if (!groupDoc.exists()) {
      throw new Error("Group not found");
    }

    const groupData = groupDoc.data();
    const balances = {};
    const billDetailsMap = {};  // Store bill details for reference

    // Initialize balances for all members
    groupData.members.forEach((member) => {
      balances[member.userId] = {
        balance: 0,
        bills: [],
        member: {
          userId: member.userId,
          displayName: member.displayName,
          email: member.email,
          photoURL: member.photoURL
        }
      };
    });

    // Calculate initial balances from bills
    bills.forEach((bill) => {
      // Store bill details
      billDetailsMap[bill.id] = {
        description: bill.description,
        amount: bill.amount,
        date: bill.date,
        splitType: bill.splitType
      };

      // Add to payer's balance
      if (balances[bill.paidBy]) {
        balances[bill.paidBy].balance += parseFloat(bill.amount);
        balances[bill.paidBy].bills.push({
          billId: bill.id,
          type: 'paid',
          amount: parseFloat(bill.amount)
        });
      }

      // Subtract from participants' balances
      bill.participants.forEach((participant) => {
        if (!participant.settled && balances[participant.userId]) {
          const share = parseFloat(participant.share.original);
          balances[participant.userId].balance -= share;
          balances[participant.userId].bills.push({
            billId: bill.id,
            type: 'owed',
            amount: share
          });
        }
      });
    });

    // Filter out negligible balances and round amounts
    const activeBalances = {};
    Object.entries(balances).forEach(([userId, userInfo]) => {
      const roundedBalance = Math.round(userInfo.balance * 100) / 100;
      if (Math.abs(roundedBalance) > 0.01) {
        activeBalances[userId] = {
          ...userInfo,
          balance: roundedBalance
        };
      }
    });

    const settlements = [];
    const workingBalances = { ...activeBalances };

    // Simplified debt resolution algorithm
    while (Object.keys(workingBalances).length > 1) {
      // Find highest creditor and debtor
      const maxCreditor = Object.entries(workingBalances).reduce(
        (max, [userId, info]) =>
          info.balance > (max?.balance || -Infinity) ? { userId, ...info } : max,
        { userId: null, balance: -Infinity }
      );

      const maxDebtor = Object.entries(workingBalances).reduce(
        (min, [userId, info]) =>
          info.balance < (min?.balance || Infinity) ? { userId, ...info } : min,
        { userId: null, balance: Infinity }
      );

      if (!maxCreditor.userId || !maxDebtor.userId) break;

      // Calculate optimal settlement amount
      const amount = Math.min(maxCreditor.balance, -maxDebtor.balance);
      if (amount <= 0.01) break;

      const roundedAmount = Math.round(amount * 100) / 100;

      // Create detailed settlement object
      const settlement = {
        from: maxDebtor.userId,
        to: maxCreditor.userId,
        amount: roundedAmount,
        fromUser: maxDebtor.member,
        toUser: maxCreditor.member,
        relatedBills: {
          // Bills where debtor owes money
          debtorBills: maxDebtor.bills.filter(b => b.type === 'owed')
            .map(b => ({
              ...b,
              ...billDetailsMap[b.billId]
            })),
          // Bills where creditor paid
          creditorBills: maxCreditor.bills.filter(b => b.type === 'paid')
            .map(b => ({
              ...b,
              ...billDetailsMap[b.billId]
            }))
        },
        createdAt: serverTimestamp(),
        status: 'pending'
      };

      settlements.push(settlement);

      // Update working balances
      workingBalances[maxCreditor.userId].balance -= roundedAmount;
      workingBalances[maxDebtor.userId].balance += roundedAmount;

      // Remove settled parties if their balance is close to zero
      if (Math.abs(workingBalances[maxCreditor.userId].balance) <= 0.01) {
        delete workingBalances[maxCreditor.userId];
      }
      if (Math.abs(workingBalances[maxDebtor.userId].balance) <= 0.01) {
        delete workingBalances[maxDebtor.userId];
      }
    }

    // Store settlements in Firestore
    const settlementsRef = doc(db, "groups", groupId, "settlements", "current");
    await setDoc(settlementsRef, {
      settlements,
      updatedAt: serverTimestamp(),
      groupId,
      totalSettlements: settlements.length,
      status: 'active'
    });

    return settlements;
  } catch (error) {
    console.error("Error calculating group settlements:", error);
    throw error;
  }
};

/**
 * Validates if proposed settlements properly resolve all debts
 * @param {Array} settlements - Array of settlement objects
 * @param {Object} initialBalances - Initial balances for all users
 * @returns {boolean} - True if settlements are valid
 */
export const validateSettlements = (settlements, initialBalances) => {
  const finalBalances = { ...initialBalances };

  settlements.forEach(settlement => {
    finalBalances[settlement.from] += settlement.amount;
    finalBalances[settlement.to] -= settlement.amount;
  });

  // Check if all balances are effectively zero
  return Object.values(finalBalances).every(balance => Math.abs(balance) <= 0.01);
};