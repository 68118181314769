import React from "react";
import { Box, Skeleton, alpha, useTheme, styled } from "@mui/material";

const CardWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  borderRadius: "12px 12px 0 0",
  overflow: "visible",
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  backdropFilter: "blur(10px)",
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  borderBottom: "none",
  maxWidth: "100%",
}));

const LoaderGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderBottom: `2px dashed ${alpha(theme.palette.divider, 0.1)}`,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const ParticipantsSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  "& .section-header": {
    marginBottom: theme.spacing(2),
    position: "relative",
    paddingBottom: theme.spacing(2),
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: -24,
      right: -24,
      borderBottom: `2px dashed ${alpha(theme.palette.divider, 0.1)}`,
    },
  },
}));

const LoaderCard = () => {
  const theme = useTheme();

  return (
    <CardWrapper>
      {/* Header Section */}
      <CardHeader>
        <Skeleton
          variant="text"
          width="60%"
          height={32}
          sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
        />
        <Skeleton
          variant="text"
          width="40%"
          height={24}
          sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
        />
        <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
          <Skeleton
            variant="rounded"
            width={80}
            height={24}
            sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
          />
          <Skeleton
            variant="rounded"
            width={80}
            height={24}
            sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
          />
        </Box>
      </CardHeader>

      {/* Participants Section */}
      <ParticipantsSection>
        <Box className="section-header">
          <Skeleton
            variant="text"
            width="30%"
            height={24}
            sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
          />
        </Box>

        {/* Payer Row */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
            pb: 2,
            borderBottom: `2px dashed ${alpha(theme.palette.divider, 0.1)}`,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
            />
            <Box>
              <Skeleton
                variant="text"
                width={100}
                height={24}
                sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
              />
              <Skeleton
                variant="text"
                width={60}
                height={20}
                sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
              />
            </Box>
          </Box>
          <Skeleton
            variant="text"
            width={80}
            height={24}
            sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
          />
        </Box>

        {/* Other Participants */}
        {[1, 2].map((index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
              />
              <Box>
                <Skeleton
                  variant="text"
                  width={100}
                  height={24}
                  sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
                />
                <Skeleton
                  variant="text"
                  width={60}
                  height={20}
                  sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
                />
              </Box>
            </Box>
            <Skeleton
              variant="text"
              width={80}
              height={24}
              sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
            />
          </Box>
        ))}
      </ParticipantsSection>

      {/* Total Amount Section */}
      <Box
        sx={{
          p: 2,
          mt: 2,
          borderTop: `2px dashed ${alpha(theme.palette.divider, 0.1)}`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: 60,
        }}
      >
        <Skeleton
          variant="text"
          width={100}
          height={24}
          sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
        />
        <Skeleton
          variant="text"
          width={120}
          height={32}
          sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
        />
      </Box>

      {/* Thank You Section */}
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          px: 3,
          py: 2,
          mt: 2,
          borderTop: `2px dashed ${alpha(theme.palette.divider, 0.1)}`,
        }}
      >
        <Skeleton
          variant="text"
          width={200}
          height={24}
          sx={{ mx: "auto", bgcolor: alpha(theme.palette.primary.main, 0.1) }}
        />
        <Skeleton
          variant="rounded"
          width={150}
          height={40}
          sx={{
            mx: "auto",
            mt: 2,
            bgcolor: alpha(theme.palette.primary.main, 0.1),
          }}
        />
      </Box>
    </CardWrapper>
  );
};

const BillsLoader = () => {
  return (
    <LoaderGrid>
      {[1, 2, 3].map((index) => (
        <LoaderCard key={index} />
      ))}
    </LoaderGrid>
  );
};

export default BillsLoader;
