import React, { useState, useEffect, useMemo } from "react";
import {
  Autocomplete,
  TextField,
  Avatar,
  Box,
  Typography,
  CircularProgress,
  styled,
  alpha,
} from "@mui/material";
import { PersonAdd as PersonAddIcon } from "@mui/icons-material";
import {
  searchUsers,
  getRecentlyInteractedUsers,
  updateRecentInteraction,
} from "../backend-services/firebase";
import { useAuth } from "../contexts/AuthContext";
import debounce from "lodash/debounce";
import UserPresence from "./UserPresence";
import RecentUsers from "./RecentUsers";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.paper, 0.4),
    transition: theme.transitions.create(["background-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.paper, 0.6),
    },
    "&.Mui-focused": {
      backgroundColor: alpha(theme.palette.background.paper, 0.8),
      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },
}));

const UserOption = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));
const EmptyStateBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const UserSearch = ({
  onUserSelect,
  excludeUsers = [],
  placeholder = "Search users...",
  multiple = false,
  value = null,
  onChange = null,
  showRecent = true,
  disabled = false,
  isGroupContext = false,
  groupMembers = [],
  readOnly = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [recentUsers, setRecentUsers] = useState([]);
  const [hasAttemptedRecentLoad, setHasAttemptedRecentLoad] = useState(false);
  const { user: currentUser } = useAuth();

  const trackInteraction = async (selectedUsers) => {
    if (!currentUser?.uid) return;

    const usersToTrack = Array.isArray(selectedUsers)
      ? selectedUsers
      : [selectedUsers];

    for (const selectedUser of usersToTrack) {
      if (selectedUser?.userId && selectedUser.userId !== currentUser.uid) {
        await updateRecentInteraction(currentUser.uid, selectedUser.userId);
      }
    }

    if (showRecent) {
      const updatedRecentUsers = await getRecentlyInteractedUsers(
        currentUser.uid
      );
      const filteredUsers = updatedRecentUsers.filter(
        (recentUser) =>
          !excludeUsers.some(
            (excludedUser) => excludedUser.userId === recentUser.userId
          )
      );
      setRecentUsers(filteredUsers);
    }
  };
  useEffect(() => {
    if (isGroupContext && groupMembers.length > 0) {
      setOptions(groupMembers);
    }
  }, [isGroupContext, groupMembers]);
  // Fetch recent users
  useEffect(() => {
    const fetchRecentUsers = async () => {
      if (!showRecent || !currentUser?.uid) return;

      try {
        const users = await getRecentlyInteractedUsers(currentUser.uid);
        const filteredUsers = users.filter(
          (recentUser) =>
            !excludeUsers.some(
              (excludedUser) => excludedUser.userId === recentUser.userId
            )
        );
        setRecentUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching recent users:", error);
        setRecentUsers([]);
      } finally {
        setHasAttemptedRecentLoad(true);
      }
    };

    fetchRecentUsers();
  }, [currentUser, excludeUsers, showRecent]);

  // Handle user selection
  const handleRecentUserSelect = async (recentUser) => {
    await trackInteraction(recentUser);
    if (multiple) {
      const newValue = Array.isArray(value)
        ? [...value, recentUser]
        : [recentUser];
      if (onChange) {
        onChange(null, newValue);
      } else if (onUserSelect) {
        onUserSelect(newValue);
      }
    } else {
      if (onChange) {
        onChange(null, recentUser);
      } else if (onUserSelect) {
        onUserSelect(recentUser);
      }
    }
  };

  // Memoize the debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchTerm, excludeUserIds) => {
        if (!searchTerm || disabled) {
          setOptions(isGroupContext ? groupMembers : []);
          setLoading(false);
          return;
        }

        try {
          if (isGroupContext) {
            const filteredMembers = groupMembers.filter(
              (member) =>
                member.displayName
                  ?.toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                member.email?.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setOptions(filteredMembers);
          } else {
            const results = await searchUsers(searchTerm, excludeUserIds);
            setOptions(results);
          }
        } catch (error) {
          console.error("Search failed:", error);
          setOptions(isGroupContext ? groupMembers : []);
        } finally {
          setLoading(false);
        }
      }, 300),
    [isGroupContext, groupMembers, disabled]
  );

  useEffect(() => {
    if (inputValue.length >= 2 && !readOnly) {
      setLoading(true);
      debouncedSearch(
        inputValue,
        excludeUsers.map((user) => user.userId)
      );
    }
  }, [inputValue, excludeUsers, readOnly]);

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleChange = async (event, newValue) => {
    if (readOnly) return;

    if (!isGroupContext) {
      await trackInteraction(newValue);
    }

    if (onChange) {
      onChange(event, newValue);
    } else if (onUserSelect) {
      onUserSelect(newValue);
    }
  };

  const renderEmptySearch = () => {
    if (inputValue.length > 0 && !loading && options.length === 0) {
      return (
        <EmptyStateBox>
          <PersonAddIcon color="action" />
          <Typography color="text.secondary" variant="body2">
            No users found matching "{inputValue}"
          </Typography>
        </EmptyStateBox>
      );
    }
    return null;
  };

  return (
    <Box>
      <StyledAutocomplete
        open={open && !readOnly}
        onOpen={() => !readOnly && setOpen(true)}
        onClose={() => setOpen(false)}
        multiple={multiple}
        value={value}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) =>
          option.userId === value?.userId
        }
        getOptionLabel={(option) => option.displayName || option.email}
        options={isGroupContext ? groupMembers : options}
        loading={loading}
        disabled={disabled || readOnly}
        onInputChange={(event, newInputValue) => {
          if (!readOnly) {
            setInputValue(newInputValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={readOnly ? "" : placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              readOnly: readOnly,
            }}
          />
        )}
        renderOption={(props, option) => (
          <UserOption key={option.userId} {...props}>
            <Box sx={{ position: "relative" }}>
              <Avatar
                src={option.photoURL || "/api/placeholder/40/40"}
                alt={option.displayName}
                sx={{ width: 32, height: 32 }}
              />
              <Box sx={{ position: "absolute", bottom: -2, right: -2 }}>
                <UserPresence userId={option.userId} />
              </Box>
            </Box>
            <Box>
              <Typography variant="body1">
                {option.displayName || "Unnamed User"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {option.email}
              </Typography>
            </Box>
          </UserOption>
        )}
      />
      {renderEmptySearch()}
      {!isGroupContext && !readOnly && (
        <RecentUsers
          recentUsers={recentUsers}
          showRecent={showRecent}
          hasAttemptedRecentLoad={hasAttemptedRecentLoad}
          handleRecentUserSelect={handleRecentUserSelect}
        />
      )}
    </Box>
  );
};

export default UserSearch;
