import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Drawer,
  IconButton,
  Avatar,
  useTheme,
  useMediaQuery,
  alpha,
  styled,
  CircularProgress,
  LinearProgress,
  Alert,
} from "@mui/material";
import {
  Close as CloseIcon,
  Check as CheckIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../../contexts/AuthContext";
import SettlementParticipantList from "./SettlementParticipantList";
import {
  getBillTransactions,
  handleSettlementComplete,
  getGroupSettlements,
} from "../../backend-services/firebase";
import { SUPPORTED_CURRENCIES } from "../../utils/currencyUtils";

// Styled Components
const DrawerContainer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: { xs: "100%", sm: "480px" },
    maxWidth: "100%",
    height: { xs: "90vh", sm: "100vh" },
    borderTopLeftRadius: { xs: theme.shape.borderRadius * 2, sm: 0 },
    borderTopRightRadius: { xs: theme.shape.borderRadius * 2, sm: 0 },
    background: `linear-gradient(145deg, ${alpha("#1A1D24", 0.98)} 0%, ${alpha(
      "#141519",
      0.98
    )} 100%)`,
    backdropFilter: "blur(10px)",
    overflowY: "auto",
  },
  "& .MuiAlert-message": {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
  },
  "& .MuiAlert-root": {
    width: "100%",
    boxSizing: "border-box",
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  margin: theme.spacing(2),
  "& .MuiAlert-message": {
    maxWidth: "100%",
    overflow: "hidden",
    wordBreak: "break-word",
  },
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: theme.spacing(4),
  },
}));

const BillSummaryCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius * 2,
  marginBottom: theme.spacing(3),
  backdropFilter: "blur(10px)",
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const PayerInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.3),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const SettlementProgress = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const LoadingOverlay = styled(motion.div)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  zIndex: theme.zIndex.drawer + 1,
  gap: theme.spacing(2),
}));

const SettlementDrawer = ({ open, onClose, bill, onBillUpdate }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user, userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || "USD";

  // State management
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [settling, setSettling] = useState(false);
  const [settlingParticipantId, setSettlingParticipantId] = useState(null);
  const [settlementProgress, setSettlementProgress] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [groupSettlements, setGroupSettlements] = useState([]);

  const isGroupBill = bill?.groupId != null;
  const paidByUser = bill?.participants?.find((p) => p.userId === bill?.paidBy);

  // Format currency with proper locale and symbol
  const formatCurrency = (value) => {
    const num = parseFloat(value);
    if (isNaN(num))
      return new Intl.NumberFormat(
        SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
        {
          style: "currency",
          currency: currencyCode || "USD",
        }
      ).format(0);

    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
      }
    ).format(Math.abs(num));
  };

  // Calculate bill summary
  const billSummary = useMemo(() => {
    if (!bill) return null;

    const totalAmount = parseFloat(bill.amount);
    const settledAmount = bill.participants
      .filter((p) => p.settled)
      .reduce((sum, p) => sum + parseFloat(p.share.original || 0), 0);

    return {
      totalAmount,
      settledAmount,
      remainingAmount: totalAmount - settledAmount,
      settlementProgress: (settledAmount / totalAmount) * 100,
      isFullySettled: Math.abs(totalAmount - settledAmount) < 0.01,
    };
  }, [bill]);

  // Load settlement data
  const loadSettlementData = async () => {
    if (!bill?.id || !open) return;

    try {
      setLoading(true);
      setError(null);

      const [billTransactions, groupSettlementsData] = await Promise.all([
        getBillTransactions(bill.id),
        isGroupBill ? getGroupSettlements(bill.groupId) : Promise.resolve(null),
      ]);

      setTransactions(billTransactions);
      if (isGroupBill && groupSettlementsData) {
        setGroupSettlements(groupSettlementsData.settlements || []);
      }
    } catch (err) {
      console.error("Error loading settlement data:", err);
      setError("Failed to load settlement details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle settlement
  const handleSettle = async (participant) => {
    if (!bill?.id || !participant?.userId || settling) return;

    try {
      setSettling(true);
      setSettlingParticipantId(participant.userId);
      setError(null);
      setSettlementProgress(10);

      // Create the settlement object with the current timestamp
      const settlement = {
        from: participant.userId,
        to: bill.paidBy,
        amount: Math.abs(participant.share.original),
        billId: bill.id,
        settledAt: new Date(), // Use regular Date instead of serverTimestamp
      };

      if (isGroupBill) {
        settlement.groupId = bill.groupId;
      }

      const result = await handleSettlementComplete(bill, settlement);

      if (result.success) {
        setSettlementProgress(100);
        await loadSettlementData();

        if (onBillUpdate) {
          await onBillUpdate(result.status === "completed");
        }

        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          onClose();
        }, 2000);
      }
    } catch (err) {
      console.error("Settlement error:", err);
      setError(
        err.message || "Failed to process settlement. Please try again."
      );
    } finally {
      setSettling(false);
      setSettlingParticipantId(null);
      setSettlementProgress(0);
    }
  };

  // Load data on open
  useEffect(() => {
    if (open && bill?.id) {
      loadSettlementData();
    }
  }, [open, bill?.id, isGroupBill, bill?.groupId]);

  return (
    <DrawerContainer
      anchor={isMobile ? "bottom" : "right"}
      open={open}
      onClose={onClose}
    >
      <DrawerContent>
        {/* Header */}
        <Box
          sx={{
            p: 2,
            borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            {isGroupBill ? "Group Bill Settlement" : "Bill Settlement"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Content */}
        <Box sx={{ p: 3, overflow: "auto", flex: 1 }}>
          {error && (
            <StyledAlert severity="error" onClose={() => setError(null)}>
              {error}
            </StyledAlert>
          )}

          {/* Bill Summary */}
          <BillSummaryCard>
            <Typography variant="h6" gutterBottom>
              {bill?.description}
            </Typography>

            <PayerInfo>
              <Avatar
                src={paidByUser?.photoURL || "/api/placeholder/40/40"}
                sx={{ width: 40, height: 40 }}
              />
              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle1">
                  {paidByUser?.displayName || paidByUser?.email}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Paid the bill
                </Typography>
              </Box>
              <Typography variant="h6" color="primary.main">
                {formatCurrency(bill?.amount)}
              </Typography>
            </PayerInfo>

            <SettlementProgress>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body2" color="text.secondary">
                  Settlement Progress
                </Typography>
                <Typography variant="body2" color="primary.main">
                  {billSummary?.settlementProgress.toFixed(0)}%
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={billSummary?.settlementProgress || 0}
                sx={{
                  height: 8,
                  borderRadius: 1,
                  bgcolor: alpha(theme.palette.primary.main, 0.1),
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 1,
                  },
                }}
              />
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ mt: 1, display: "block" }}
              >
                {formatCurrency(billSummary?.remainingAmount)} remaining
              </Typography>
            </SettlementProgress>
          </BillSummaryCard>

          {/* Settlement List */}
          <SettlementParticipantList
            participants={bill?.participants || []}
            paidBy={bill?.paidBy}
            currentUserId={user?.uid}
            onSettleShare={handleSettle}
            loading={settling}
            loadingParticipantId={settlingParticipantId}
            transactions={transactions}
            totalAmount={billSummary?.totalAmount}
            splitType={bill?.splitType}
            items={bill?.items || []}
          />
        </Box>

        {/* Loading Overlay */}
        <AnimatePresence>
          {loading && (
            <LoadingOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <CircularProgress />
              <Typography>Loading settlement details...</Typography>
            </LoadingOverlay>
          )}
        </AnimatePresence>

        {/* Success Overlay */}
        <AnimatePresence>
          {showSuccess && (
            <LoadingOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <CheckIcon sx={{ fontSize: 60, color: "success.main" }} />
              <Typography variant="h6" color="primary">
                Settlement Complete!
              </Typography>
            </LoadingOverlay>
          )}
        </AnimatePresence>
      </DrawerContent>
    </DrawerContainer>
  );
};

export default SettlementDrawer;
