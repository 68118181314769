import React from "react";
import {
  Box,
  Typography,
  styled,
  Container,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import BillCard from "./BillCard";
import BillsLoader from "./BillsLoader";

const PageContainer = styled(Container)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  margin: "0 auto",
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(3),
    maxWidth: "1200px",
  },
}));

const BillsGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

const EmptyStateContainer = motion(
  styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 400,
    padding: theme.spacing(4),
    backdropFilter: "blur(10px)",
    position: "relative",
    overflow: "hidden",
  }))
);

const GroupBillsList = ({ bills, loading, onBillUpdate }) => {
  const renderEmptyState = () => (
    <EmptyStateContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Typography variant="h6" gutterBottom>
        No Bills Yet
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ maxWidth: 300, textAlign: "center" }}
      >
        Start by adding a bill to split expenses with group members
      </Typography>
    </EmptyStateContainer>
  );

  if (loading) {
    return <BillsLoader />;
  }

  return (
    <PageContainer>
      <AnimatePresence mode="wait">
        {bills && bills.length > 0 ? (
          <BillsGrid>
            {bills.map((bill) => (
              <BillCard 
                key={bill.id} 
                bill={bill} 
                onBillUpdate={onBillUpdate}
              />
            ))}
          </BillsGrid>
        ) : (
          renderEmptyState()
        )}
      </AnimatePresence>
    </PageContainer>
  );
};

export default GroupBillsList;