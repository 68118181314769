import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Container,
  ThemeProvider,
  createTheme,
  CssBaseline,
  alpha,
  Alert,
  Snackbar,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import BillsList from "./BillsList";
import {
  getUserBills,
  calculateUserBalances,
  updateBillStatus,
} from "../backend-services/firebase";

// Create custom theme
const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#181A1F",
      paper: "#222429",
    },
    primary: {
      main: "#F5DB54",
    },
    secondary: {
      main: "#9968FF",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "rgba(255, 255, 255, 0.7)",
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
      letterSpacing: "-0.02em",
    },
    h6: {
      fontWeight: 600,
      letterSpacing: "-0.01em",
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage:
            "linear-gradient(145deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(255, 255, 255, 0.05)",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          textTransform: "none",
          fontWeight: 600,
          padding: "10px 20px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

const BillSplitter = () => {
  const [loadingBills, setLoadingBills] = useState(false);
  const [billError, setBillError] = useState(null);
  const [mode] = useState("single");
  const [bills, setBills] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [settlements, setSettlements] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const { user } = useAuth();

  const fetchBills = useCallback(async () => {
    if (!user?.uid || mode !== "single") return;

    try {
      setLoadingBills(true);
      setBillError(null);
      console.log("Starting to fetch bills for user:", user.uid);

      const userBills = await getUserBills(user.uid);
      console.log("Fetched bills:", userBills);

      // Sort bills by status and date
      const sortedBills = userBills.sort((a, b) => {
        // First sort by status
        if (a.status === "completed" && b.status !== "completed") return 1;
        if (a.status !== "completed" && b.status === "completed") return -1;

        // Then sort by date (newest first)
        const dateA = a.date?.toDate?.() || new Date(a.date);
        const dateB = b.date?.toDate?.() || new Date(b.date);
        return dateB - dateA;
      });

      console.log("Sorted bills:", sortedBills);
      setBills(sortedBills);
      await updateSettlements();
    } catch (error) {
      console.error("Error fetching bills:", error);
      setBillError("Failed to load bills");
    } finally {
      setLoadingBills(false);
    }
  }, [user, mode]);

  useEffect(() => {
    fetchBills();
  }, [fetchBills, refreshTrigger]);

  const updateSettlements = async () => {
    if (!user?.uid) return;

    try {
      const settlements = await calculateUserBalances(user.uid);
      setSettlements(
        Object.entries(settlements).map(([userId, amount]) => ({
          from: amount < 0 ? user.uid : userId,
          to: amount < 0 ? userId : user.uid,
          amount: Math.abs(amount),
        }))
      );
    } catch (error) {
      console.error("Error updating settlements:", error);
    }
  };

  const handleBillUpdate = useCallback(async (updatedBills) => {
    try {
      if (Array.isArray(updatedBills)) {
        // If we receive updated bills, use them directly
        setBills(prevBills => 
          prevBills.map(bill => {
            const updatedBill = updatedBills.find(u => u.id === bill.id);
            return updatedBill || bill;
          })
        );
      } else {
        // Otherwise, trigger a refresh
        setRefreshTrigger((prev) => prev + 1);
      }

      await updateSettlements();
      setSuccessMessage("Bill updated successfully");
    } catch (error) {
      console.error("Error updating bills:", error);
      setBillError("Failed to update bills. Please refresh the page.");
    }
  }, []);

  const handleSettleBill = async (billId, status) => {
    try {
      await updateBillStatus(billId, status);
      await fetchBills(); // Refresh bills after status update
      setSuccessMessage("Bill settled successfully");
    } catch (error) {
      console.error("Error settling bill:", error);
      setBillError("Failed to settle bill. Please try again.");
    }
  };

  const clearError = useCallback(() => {
    setBillError(null);
  }, []);

  const handleCloseSnackbar = () => {
    setSuccessMessage("");
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: "100vh",
          background: `linear-gradient(135deg, ${alpha(
            "#181A1F",
            0.95
          )} 0%, ${alpha("#181A1F", 0.98)} 100%)`,
        }}
      >
        <Container maxWidth="lg">
          {billError && (
            <Alert severity="error" onClose={clearError} sx={{ mb: 2 }}>
              {billError}
            </Alert>
          )}

          <BillsList
            bills={bills}
            loading={loadingBills}
            error={billError}
            onBillUpdate={handleBillUpdate}
            onSettleBill={handleSettleBill}
          />

          <Snackbar
            open={!!successMessage}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="success"
              sx={{ width: "100%" }}
            >
              {successMessage}
            </Alert>
          </Snackbar>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default BillSplitter;
