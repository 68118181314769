import React from "react";
import { Box, styled, alpha } from "@mui/material";

const StyledMainContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  background: `linear-gradient(135deg, ${alpha("#181A1F", 0.95)} 0%, ${alpha(
    "#181A1F",
    0.98
  )} 100%)`,
  padding: theme.spacing(0), // Remove padding for mobile
  paddingBottom: theme.spacing(8), // Space for bottom navigation
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(8), // Reduced from 10
    paddingBottom: theme.spacing(4),
  },
}));

const MainContainer = ({ children }) => {
  return <StyledMainContainer>{children}</StyledMainContainer>;
};

export default MainContainer;
