import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  alpha,
  styled,
  Avatar,
  CircularProgress,
  Alert,
  Snackbar,
  LinearProgress,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import UserSearch from "./UserSearch";
import { createGroup } from "../backend-services/firebase";
import _ from "lodash";

const GROUP_NAME_MAX_LENGTH = 50;
const GROUP_NAME_MIN_LENGTH = 3;
const MAX_GROUP_MEMBERS = 20;
const MIN_GROUP_MEMBERS = 1;

// Styled components
const PageContainer = styled(Container)(({ theme }) => ({
  maxWidth: "800px",
  padding: theme.spacing(3),
  margin: "0 auto",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
}));

const BackButtonRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginBottom: "4px",
});

const BackButton = styled(Button)({
  padding: "4px",
  minWidth: "unset",
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover": {
    background: "none",
    color: "rgba(255, 255, 255, 0.9)",
  },
});

const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "24px",
  fontWeight: "bold",
  marginBottom: theme.spacing(4),
}));

const FormCard = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
}));

const FormField = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  "&:last-child": {
    marginBottom: 0,
  },
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: alpha(theme.palette.background.default, 0.6),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    padding: "12px 16px",
  },
}));

const CreateGroupButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  width: "100%",
  padding: "12px",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
  },
  "&.Mui-disabled": {
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
    color: theme.palette.common.black,
  },
}));

const ValidationError = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  color: theme.palette.error.main,
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.error.main, 0.1),
  "& .MuiSvgIcon-root": {
    fontSize: "1.2rem",
  },
}));

const CreateGroup = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [groupName, setGroupName] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [memberError, setMemberError] = useState("");
  const [creationProgress, setCreationProgress] = useState(0);

  const handleBack = () => {
    navigate("/groups");
  };

  const validateGroupName = useCallback((name) => {
    if (!name?.trim()) {
      return "Group name is required";
    }
    if (name.length < GROUP_NAME_MIN_LENGTH) {
      return `Group name must be at least ${GROUP_NAME_MIN_LENGTH} characters`;
    }
    if (name.length > GROUP_NAME_MAX_LENGTH) {
      return `Group name cannot exceed ${GROUP_NAME_MAX_LENGTH} characters`;
    }
    // Check for valid characters (letters, numbers, spaces, and basic punctuation)
    if (!/^[\w\s\-.,!?&()]+$/i.test(name)) {
      return "Group name contains invalid characters";
    }
    return "";
  }, []);

  const validateMembers = useCallback((members) => {
    if (!Array.isArray(members)) {
      return "Invalid member selection";
    }
    if (members.length < MIN_GROUP_MEMBERS) {
      return `Please add at least ${MIN_GROUP_MEMBERS} member`;
    }
    if (members.length > MAX_GROUP_MEMBERS) {
      return `Group cannot have more than ${MAX_GROUP_MEMBERS} members`;
    }

    // Check for duplicate members
    const memberIds = members.map((m) => m.userId);
    const uniqueIds = [...new Set(memberIds)];
    if (uniqueIds.length !== memberIds.length) {
      return "Duplicate members detected";
    }

    return "";
  }, []);

  const handleGroupNameChange = (e) => {
    const value = e.target.value;
    setGroupName(value);
    const error = validateGroupName(value);
    setNameError(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedName = groupName.trim();

    // Final validation before submission
    const nameValidation = validateGroupName(trimmedName);
    const memberValidation = validateMembers(selectedMembers);

    if (nameValidation || memberValidation) {
      setNameError(nameValidation);
      setMemberError(memberValidation);
      return;
    }

    try {
      setIsCreating(true);
      setError("");
      const progressInterval = simulateProgress();

      if (!user?.uid) {
        throw new Error("User is not authenticated");
      }

      // Filter out creator from selected members if present
      const filteredMembers = selectedMembers.filter(
        (member) => member.userId !== user.uid
      );

      const groupData = {
        name: trimmedName,
        createdBy: user.uid,
        members: filteredMembers,
      };

      const groupId = await createGroup(groupData);

      clearInterval(progressInterval);
      setCreationProgress(100);
      setSuccessMessage("Group created successfully!");

      setTimeout(() => {
        navigate(`/groups/${groupId}`);
      }, 1500);
    } catch (error) {
      console.error("Error in group creation:", error);
      setError(error.message || "Failed to create group");
      setCreationProgress(0);
    } finally {
      setIsCreating(false);
    }
  };

  const handleMembersChange = (event, newMembers) => {
    const validMembers = _.uniqBy(
      newMembers.filter(
        (member) => member && member.userId && typeof member.userId === "string"
      ),
      "userId"
    );

    const error = validateMembers(validMembers);
    setMemberError(error);
    setSelectedMembers(validMembers);
  };

  const simulateProgress = () => {
    setCreationProgress(0);
    const interval = setInterval(() => {
      setCreationProgress((prev) => {
        if (prev >= 90) {
          clearInterval(interval);
          return 90;
        }
        return prev + 10;
      });
    }, 200);
    return interval;
  };

  const isFormValid =
    !nameError &&
    !memberError &&
    groupName.trim().length >= GROUP_NAME_MIN_LENGTH &&
    selectedMembers.length >= MIN_GROUP_MEMBERS;

  return (
    <PageContainer maxWidth="md">
      <BackButtonRow>
        <BackButton
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/groups")}
          disableRipple
        >
          Back
        </BackButton>
      </BackButtonRow>

      <PageTitle>Create Group</PageTitle>

      <FormCard sx={{ position: "relative" }}>
        {isCreating && (
          <LinearProgress
            variant="determinate"
            value={creationProgress}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              borderTopLeftRadius: 1,
              borderTopRightRadius: 1,
            }}
          />
        )}
        <Box sx={{ opacity: isCreating ? 0.3 : 1 }}>
          <FormField>
            <StyledInput
              fullWidth
              placeholder="Group Name"
              value={groupName}
              onChange={handleGroupNameChange}
              error={Boolean(nameError)}
              helperText={nameError}
              inputProps={{
                maxLength: GROUP_NAME_MAX_LENGTH,
              }}
              disabled={isCreating}
            />
          </FormField>

          <FormField>
            <UserSearch
              multiple
              value={selectedMembers}
              onChange={handleMembersChange}
              excludeUsers={[{ userId: user?.uid }]}
              showRecent={false}
              placeholder="Search and add members..."
              disabled={isCreating}
            />
            {memberError && (
              <ValidationError>
                <ErrorOutlineIcon />
                <Typography variant="caption">{memberError}</Typography>
              </ValidationError>
            )}
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ mt: 1, display: "block" }}
            >
              {`Selected: ${selectedMembers.length}/${MAX_GROUP_MEMBERS} members`}
            </Typography>
          </FormField>

          {selectedMembers.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Selected Members:
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {selectedMembers.map((member) => (
                  <Box
                    key={member.userId}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      p: 1,
                      bgcolor: (theme) =>
                        alpha(theme.palette.background.paper, 0.4),
                      borderRadius: 1,
                    }}
                  >
                    <Avatar
                      src={member.photoURL || "/api/placeholder/24/24"}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      {member.displayName || member.email}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <CreateGroupButton
            variant="contained"
            onClick={handleSubmit}
            disabled={!isFormValid || isCreating}
            sx={{ mt: 3 }}
          >
            {isCreating ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                Creating Group...
              </Box>
            ) : (
              "Create Group"
            )}
          </CreateGroupButton>
        </Box>
      </FormCard>

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSuccessMessage("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default CreateGroup;
