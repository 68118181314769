import React, { useState, useEffect,useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Container,
  Avatar,
  AvatarGroup,
  IconButton,
  alpha,
  styled,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Chip,
  Tooltip,
  Paper,
  LinearProgress,
  useTheme,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
  Receipt as ReceiptIcon,
  Group as GroupIcon,
  CalendarMonth as CalendarIcon,
  StickyNote2 as EmptyIcon,
} from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";
import { db, getGroupSettlements } from "../backend-services/firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import {
  subscribeToGroup,
  getBillsByGroup,
} from "../backend-services/firebase";
import BillsList from "./BillsList";
import GroupBillErrorBoundary from "./GroupBillErrorBoundary";
import GroupBillsList from "./GroupBillsList";

const PageContainer = styled(Container)(({ theme }) => ({
  maxWidth: "1200px",
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(8),
}));

const BackButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  minWidth: "unset",
  color: alpha(theme.palette.common.white, 0.7),
  "&:hover": {
    background: "none",
    color: alpha(theme.palette.common.white, 0.9),
  },
}));

const GroupHeader = styled(Paper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "4px",
  },
}));

const HeaderContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const GroupTitleSection = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: theme.spacing(2),
}));

const GroupTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

const ActionButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
}));

const AddBillButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.shape.borderRadius,
  fontWeight: 600,
  marginTop: theme.spacing(3),
  width: "100%",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
  },
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const GroupStats = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

const StatCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
}));

const MemberSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  borderRadius: theme.shape.borderRadius,
}));

const TabsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  marginBottom: theme.spacing(3),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  position: "relative",
  overflow: "hidden",
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 48,
  "& .MuiTabs-flexContainer": {
    gap: theme.spacing(2),
  },
  "& .MuiTab-root": {
    minHeight: 48,
    padding: theme.spacing(1, 2),
    color: alpha(theme.palette.common.white, 0.7),
    fontSize: "0.875rem",
    fontWeight: 500,
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.common.white,
    },
  },
  "& .MuiTabs-indicator": {
    height: 3,
    backgroundColor: theme.palette.primary.main,
  },
}));

const TabLabel = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const TabPanel = ({ children, value, index }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`group-tabpanel-${index}`}
    aria-labelledby={`group-tab-${index}`}
  >
    {value === index && children}
  </Box>
);

const ContentContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const EmptyStateContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(6),
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  borderRadius: theme.shape.borderRadius * 2,
  textAlign: "center",
}));

const GroupDetails = () => {
  const theme = useTheme();
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || "USD";
  // States
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [bills, setBills] = useState([]);
  const [loadingBills, setLoadingBills] = useState(true);
  const [billsError, setBillsError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [billsRefresh, setBillsRefresh] = useState(0);

  const [groupStats, setGroupStats] = useState({
    totalBills: 0,
    pendingBills: 0,
    completedBills: 0,
    totalAmount: 0,
    settledAmount: 0,
    pendingAmount: 0,
    settlementProgress: 0,
  });

  const formatCurrency = (value) => {
    const num = parseFloat(value);
    if (isNaN(num)) {
      return new Intl.NumberFormat(
        SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
        {
          style: "currency",
          currency: currencyCode || "USD",
        }
      ).format(0);
    }

    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
      }
    ).format(num);
  };

  useEffect(() => {
    if (!groupId) return;

    console.log("Subscribing to group:", groupId);
    setLoading(true);
    setError("");

    const unsubscribe = subscribeToGroup(groupId, (updatedGroup) => {
      console.log("Received group data:", updatedGroup);
      if (updatedGroup) {
        setGroup(updatedGroup);
      } else {
        setError("Group not found");
      }
      setLoading(false);
    });

    return () => {
      console.log("Unsubscribing from group");
      unsubscribe();
    };
  }, [groupId]);
  useEffect(() => {
    if (!groupId || !group) return;

    const fetchBillsAndStats = async () => {
      setLoadingBills(true);
      setBillsError(null);
    
      try {
        const groupBills = await getBillsByGroup(groupId);
        const sortedBills = groupBills.sort((a, b) => {
          const dateA = a.date?.toDate?.() || new Date(a.date);
          const dateB = b.date?.toDate?.() || new Date(b.date);
          return dateB - dateA;
        });
    
        setBills(sortedBills);
        setGroupStats(calculateGroupStats(sortedBills));
    
      } catch (error) {
        console.error("Error fetching bills and stats:", error);
        setBillsError("Failed to load group bills and statistics");
      } finally {
        setLoadingBills(false);
      }
    };

    fetchBillsAndStats();
  }, [groupId, group]);

  const handleBillUpdate = useCallback(async () => {
    try {
      setLoadingBills(true);
      setBillsError(null);
  
      const groupBills = await getBillsByGroup(groupId);
      const sortedBills = groupBills.sort((a, b) => {
        const dateA = a.date?.toDate?.() || new Date(a.date);
        const dateB = b.date?.toDate?.() || new Date(b.date);
        return dateB - dateA;
      });
  
      setBills(sortedBills);
      
      // Recalculate stats after bills update
      const stats = calculateGroupStats(sortedBills);
      setGroupStats(stats);
  
    } catch (error) {
      console.error("Error refreshing bills:", error);
      setBillsError("Failed to refresh bills data");
    } finally {
      setLoadingBills(false);
    }
  }, [groupId]);

  const handleBack = () => {
    navigate("/groups");
  };

  const handleAddBill = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/groups/${groupId}/add-bill`);
  };

  const calculateGroupStats = (bills) => {
    let totalAmount = 0;
    let settledAmount = 0;
    let completedBills = 0;
    let pendingBills = 0;
  
    bills.forEach((bill) => {
      const billAmount = parseFloat(bill.amount || 0);
      totalAmount += billAmount;
  
      const allParticipantsSettled = bill.participants.every(
        (p) => p.settled || p.userId === bill.paidBy
      );
  
      if (bill.status === "completed" || allParticipantsSettled) {
        completedBills++;
        settledAmount += billAmount;
      } else {
        pendingBills++;
        const settledParticipants = bill.participants.filter(
          (p) => p.settled || p.userId === bill.paidBy
        );
        const settledShare = settledParticipants.reduce(
          (sum, p) => sum + parseFloat(p.share?.original || 0),
          0
        );
        settledAmount += settledShare;
      }
    });
  
    const pendingAmount = Math.max(0, totalAmount - settledAmount);
    const settlementProgress =
      totalAmount > 0 ? (settledAmount / totalAmount) * 100 : 0;
  
    return {
      totalBills: bills.length,
      pendingBills,
      completedBills,
      totalAmount,
      settledAmount,
      pendingAmount,
      settlementProgress,
    };
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "400px",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!group) {
    return (
      <Container>
        <Alert severity="error">Group not found</Alert>
      </Container>
    );
  }

  const isCreator = group.createdBy === user?.uid;
  const createdDate = group.createdAt?.toDate() || new Date();
  const totalBills = bills.length;

  const renderEmptyState = (type) => (
    <EmptyStateContainer>
      <EmptyIcon>
        {type === "bills" ? <ReceiptIcon /> : <GroupIcon />}
      </EmptyIcon>
      <Typography variant="h6" gutterBottom>
        {type === "bills" ? "No Bills Yet" : "No Members Yet"}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400 }}>
        {type === "bills"
          ? `This group doesn't have any bills yet. Bills added will appear here.`
          : `This group doesn't have any members yet.`}
      </Typography>
    </EmptyStateContainer>
  );

  const renderBillsTab = () => {
    if (loadingBills) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (billsError) {
      return (
        <Alert
          severity="error"
          sx={{ my: 2 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setBillsRefresh((prev) => prev + 1)}
            >
              Retry
            </Button>
          }
        >
          {billsError}
        </Alert>
      );
    }

    if (!bills.length) {
      return (
        <Box
          sx={{
            textAlign: "center",
            py: 6,
            px: 2,
            bgcolor: (theme) => alpha(theme.palette.background.paper, 0.4),
            borderRadius: 2,
          }}
        >
          <ReceiptIcon
            sx={{ fontSize: 48, color: "primary.main", opacity: 0.5, mb: 2 }}
          />
          <Typography variant="h6" gutterBottom>
            No Bills Yet
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 3 }}>
            Start by adding a bill to split expenses with group members
          </Typography>
        </Box>
      );
    }

    return (
      <GroupBillErrorBoundary
        onRetry={() => setBillsRefresh((prev) => prev + 1)}
      >
        <GroupBillsList
          bills={bills}
          loading={loadingBills}
          onBillUpdate={handleBillUpdate}
        />
      </GroupBillErrorBoundary>
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "400px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Container>
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => window.location.reload()}
            >
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      </Container>
    );
  }
  if (!group) {
    return (
      <Container>
        <Alert severity="error">Group not found</Alert>
      </Container>
    );
  }
  return (
    <PageContainer>
      <Box sx={{ mb: 2 }}>
        <BackButton startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Back to Groups
        </BackButton>
      </Box>

      <GroupHeader>
        <HeaderContent>
          <GroupTitleSection>
            <GroupTitle>
              <Typography variant="h4" color="primary" fontWeight="bold">
                {group.name}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CalendarIcon sx={{ fontSize: 16, color: "text.secondary" }} />
                <Typography variant="body2" color="text.secondary">
                  Created {createdDate.toLocaleDateString()}
                </Typography>
                {isCreator && (
                  <Chip
                    label="Admin"
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ height: 24 }}
                  />
                )}
              </Box>
            </GroupTitle>
            <ActionButtons>
              <Tooltip title="Add Bill">
                <IconButton
                  onClick={handleAddBill}
                  sx={{
                    color: "text.secondary",
                    "&:hover": { color: "text.primary" },
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </ActionButtons>
          </GroupTitleSection>

          <MemberSection>
            <AvatarGroup
              max={5}
              sx={{
                "& .MuiAvatar-root": {
                  width: 40,
                  height: 40,
                  fontSize: "1rem",
                  border: "2px solid",
                  borderColor: "background.paper",
                },
              }}
            >
              {group.members.map((member) => (
                <Tooltip
                  key={member.userId}
                  title={member.displayName || member.email}
                >
                  <Avatar
                    src={member.photoURL || "/api/placeholder/40/40"}
                    alt={member.displayName}
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
            <Box>
              <Typography variant="subtitle1" fontWeight={500}>
                {group.members.length}{" "}
                {group.members.length === 1 ? "Member" : "Members"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {group.members.some((m) => m.userId === user?.uid)
                  ? "You're a member"
                  : "You're not a member"}
              </Typography>
            </Box>
          </MemberSection>

          <GroupStats>
            <StatCard>
              <Typography variant="body2" color="text.secondary">
                Total Bills
              </Typography>
              <Typography variant="h4" color="primary" fontWeight="bold">
                {groupStats.totalBills}
              </Typography>
            </StatCard>

            <StatCard>
              <Typography variant="body2" color="text.secondary">
                Pending Bills
              </Typography>
              <Typography variant="h4" color="warning.main" fontWeight="bold">
                {groupStats.pendingBills}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {formatCurrency(groupStats.pendingAmount)} remaining
              </Typography>
            </StatCard>

            <StatCard>
              <Typography variant="body2" color="text.secondary">
                Completed Bills
              </Typography>
              <Typography variant="h4" color="success.main" fontWeight="bold">
                {groupStats.completedBills}
              </Typography>
              <Typography variant="caption" color="success.main">
                {formatCurrency(groupStats.settledAmount)} settled
              </Typography>
            </StatCard>

            <StatCard>
              <Typography variant="body2" color="text.secondary">
                Settlement Progress
              </Typography>
              <Box sx={{ position: "relative", pt: 0.5 }}>
                <LinearProgress
                  variant="determinate"
                  value={groupStats.settlementProgress}
                  sx={{
                    height: 8,
                    borderRadius: 1,
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    "& .MuiLinearProgress-bar": {
                      borderRadius: 1,
                    },
                  }}
                />
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: "100%",
                    mt: 0.5,
                  }}
                >
                  {groupStats.settlementProgress.toFixed(1)}%
                </Typography>
              </Box>
            </StatCard>
          </GroupStats>
        </HeaderContent>
      </GroupHeader>

      <Box sx={{ mb: 3 }}>
        <StyledTabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label={
              <TabLabel>
                <ReceiptIcon sx={{ fontSize: 20 }} />
                <span>Bills ({totalBills})</span>
              </TabLabel>
            }
          />
          <Tab
            label={
              <TabLabel>
                <GroupIcon sx={{ fontSize: 20 }} />
                <span>Members ({group.members.length})</span>
              </TabLabel>
            }
          />
        </StyledTabs>
      </Box>

      <TabPanel value={activeTab} index={0}>
        {renderBillsTab()}
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <ContentContainer>
          {group.members.length === 0
            ? renderEmptyState("members")
            : group.members.map((member) => (
                <Box
                  key={member.userId}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mb: 2,
                    p: 2,
                    backgroundColor: (theme) =>
                      alpha(theme.palette.background.default, 0.4),
                    borderRadius: 1,
                    "&:last-child": { mb: 0 },
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      backgroundColor: (theme) =>
                        alpha(theme.palette.background.default, 0.6),
                    },
                  }}
                >
                  <Avatar
                    src={member.photoURL || "/api/placeholder/40/40"}
                    alt={member.displayName}
                    sx={{ width: 48, height: 48 }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1" fontWeight={500}>
                      {member.displayName || member.email}
                      {member.userId === group.createdBy && (
                        <Chip
                          label="Creator"
                          size="small"
                          color="primary"
                          variant="outlined"
                          sx={{ ml: 1, height: 20 }}
                        />
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {member.email}
                    </Typography>
                  </Box>
                </Box>
              ))}
        </ContentContainer>
      </TabPanel>
    </PageContainer>
  );
};

export default GroupDetails;
