import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Avatar,
  AvatarGroup,
  alpha,
  styled,
  Container,
  CircularProgress,
  Alert,
  Tooltip,
  Chip,
} from "@mui/material";
import {
  Group as GroupIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../contexts/AuthContext";
import { subscribeToUserGroups } from "../backend-services/firebase";

const ActionCard = styled(motion.div)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.4),
  backdropFilter: 'blur(8px)',
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  textAlign: 'center',
  '&:hover': {
    transform: 'translateY(-4px)',
    background: alpha(theme.palette.background.paper, 0.5),
  },
}));

const ActionGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

const ActionIcon = styled(Box)(({ theme }) => ({
  width: 64,
  height: 64,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  '& svg': {
    fontSize: 32,
    color: theme.palette.primary.main,
  },
}));

const PageContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: "1200px",
}));

const PageHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "stretch",
    marginBottom: theme.spacing(2),
  },
}));

const CreateGroupButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.shape.borderRadius * 1.5,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: theme.spacing(1.2, 2),
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.main, 0.9),
  },
}));

const GroupCard = styled(motion.div)(({ theme }) => ({
  height: "100%",
  "& .MuiCard-root": {
    height: "100%",
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: "blur(10px)",
    borderRadius: theme.shape.borderRadius * 2,
    padding: {
      xs: theme.spacing(2),
      sm: theme.spacing(3),
    },
    touchAction: "manipulation",
    cursor: "pointer",
    WebkitTapHighlightColor: "transparent",
    transition: "all 0.3s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      "&:active": {
        transform: "scale(0.98)",
        transition: "transform 0.1s",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "&:hover": {
        transform: "translateY(-4px)",
      },
    },
  },
}));

const EmptyState = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(6),
  textAlign: "center",
  margin: theme.spacing(4, 0),
}));

const BillsGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(2),
  gridTemplateColumns: {
    xs: "1fr", // Single column on mobile
    sm: "repeat(2, 1fr)", // 2 columns on tablet
    lg: "repeat(3, 1fr)", // 3 columns on desktop
  },
  padding: {
    xs: theme.spacing(1),
    sm: theme.spacing(2),
  },
  marginBottom: {
    xs: theme.spacing(8), // Extra space for bottom navigation
    md: theme.spacing(3),
  },
}));

const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  "& .MuiAvatar-root": {
    width: {
      xs: 28,
      sm: 32,
    },
    height: {
      xs: 28,
      sm: 32,
    },
    fontSize: {
      xs: "0.75rem",
      sm: "0.875rem",
    },
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));

const GroupName = styled(Typography)(({ theme }) => ({
  fontSize: {
    xs: "1.25rem",
    sm: "1.5rem",
  },
  lineHeight: 1.2,
  marginBottom: theme.spacing(1),
}));

const GroupsList = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.uid) {
      setLoading(false);
      return;
    }

    const unsubscribe = subscribeToUserGroups(user.uid, (updatedGroups) => {
      console.log("Received groups:", updatedGroups);
      setGroups(updatedGroups);
      setLoading(false);
      setError(null);
    });

    return () => unsubscribe();
  }, [user]);

  const handleCreateGroup = () => {
    navigate("/groups/create");
  };

  const handleAddBill = () => {
    navigate("/groups/add-bill");
  };

  const handleGroupClick = (groupId) => {
    navigate(`/groups/${groupId}`);
  };

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => window.location.reload()}
            >
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PageContainer>
      <PageHeader>
        <Typography variant="h4" color="primary.main" fontWeight="bold">
          Groups
        </Typography>
      </PageHeader>

      <ActionGrid>
        <ActionCard
          onClick={handleCreateGroup}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <ActionIcon>
            <GroupIcon />
          </ActionIcon>
          <Typography variant="h6">Create New Group</Typography>
          <Typography variant="body2" color="text.secondary">
            Create a group to split expenses with friends or colleagues
          </Typography>
        </ActionCard>

        <ActionCard
          onClick={handleAddBill}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          <ActionIcon>
            <ReceiptIcon />
          </ActionIcon>
          <Typography variant="h6">Add New Bill</Typography>
          <Typography variant="body2" color="text.secondary">
            Add a bill and we'll automatically create a group for splitting
          </Typography>
        </ActionCard>
      </ActionGrid>

      {groups.length === 0 ? (
        <EmptyState
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <GroupIcon
            sx={{
              fontSize: 48,
              color: "primary.main",
              mb: 2,
              opacity: 0.8,
            }}
          />
          <Typography variant="h6" gutterBottom>
            No Groups Yet
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 3, maxWidth: 400 }}
          >
            Create a group to start splitting bills with your friends
          </Typography>
        </EmptyState>
      ) : (
        <AnimatePresence>
          <BillsGrid>
            {groups.map((group) => (
              <GroupCard
                key={group.id}
                onClick={() => handleGroupClick(group.id)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <Card>
                  <CardContent>
                    <GroupName>{group.name}</GroupName>
                    <Typography variant="caption" color="text.secondary">
                      {group.createdAt?.toDate().toLocaleDateString()}
                    </Typography>

                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <StyledAvatarGroup max={4}>
                          {group.members.map((member) => (
                            <Tooltip
                              key={member.userId}
                              title={member.displayName || member.email}
                            >
                              <Avatar
                                src={
                                  member.photoURL || "/api/placeholder/32/32"
                                }
                                alt={member.displayName}
                              />
                            </Tooltip>
                          ))}
                        </StyledAvatarGroup>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mt: 1 }}
                        >
                          {group.members.length}{" "}
                          {group.members.length === 1 ? "member" : "members"}
                        </Typography>
                      </Box>

                      {user?.uid === group.createdBy && (
                        <Chip
                          label="Admin"
                          size="small"
                          color="primary"
                          variant="outlined"
                          sx={{ height: 24 }}
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </GroupCard>
            ))}
          </BillsGrid>
        </AnimatePresence>
      )}
    </PageContainer>
  );
};

export default GroupsList;
