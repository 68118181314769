import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  alpha,
  styled,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Receipt as ReceiptIcon,
  Settings as SettingsIcon,
  History as HistoryIcon,
  Group as GroupIcon,
} from "@mui/icons-material";

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  height: 64,
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(20px)",
  borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  zIndex: 1000,
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => ({
    color: alpha(theme.palette.common.white, 0.5),
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  })
);

const BottomNav = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Define navigation items as an array of objects
  const navigationItems = [
    {
      label: "Dashboard",
      value: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      label: "Groups",
      value: "/groups",
      icon: <GroupIcon />,
    },
    {
      label: "Transactions",
      value: "/transactions",
      icon: <HistoryIcon />,
    },
    {
      label: "Settings",
      value: "/settings",
      icon: <SettingsIcon />,
    },
  ];

  const getCurrentValue = () => {
    return navigationItems.find(item => location.pathname === item.value)?.value || false;
  };

  return (
    <Box sx={{ display: { xs: "block", md: "none" } }}>
      <StyledBottomNavigation
        value={getCurrentValue()}
        onChange={(_, newValue) => {
          navigate(newValue);
        }}
        showLabels
      >
        {navigationItems.map((item) => (
          <StyledBottomNavigationAction
            key={item.value}
            label={item.label}
            value={item.value}
            icon={item.icon}
          />
        ))}
      </StyledBottomNavigation>
    </Box>
  );
};

export default BottomNav;