import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  alpha,
  styled,
  Avatar,
  Chip,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Snackbar,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import {
  Email as EmailIcon,
  CalendarToday as CalendarIcon,
  CheckCircle as VerifiedIcon,
  CurrencyExchange as CurrencyIcon,
} from "@mui/icons-material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../backend-services/firebase";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";

const ResponsiveContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3),
  },
  maxWidth: {
    xs: "100%",
    sm: "600px",
    md: "800px",
  },
  marginLeft: "auto",
  marginRight: "auto",
}));

const ProfileCard = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.background.paper, 0.8)} 0%, 
    ${alpha(theme.palette.background.paper, 0.6)} 100%)`,
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  position: "relative",
  overflow: "hidden",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
  },
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, 
    ${alpha(theme.palette.primary.main, 0.1)} 0%, 
    ${alpha(theme.palette.secondary.main, 0.1)} 100%)`,
  padding: theme.spacing(2.5),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 1,
    background: `linear-gradient(90deg, 
      transparent 0%, 
      ${alpha(theme.palette.primary.main, 0.2)} 50%,
      transparent 100%
    )`,
  },
}));

const ProfileContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 90,
  height: 90,
  [theme.breakpoints.up("sm")]: {
    width: 120,
    height: 120,
  },
  border: `4px solid ${alpha(theme.palette.primary.main, 0.3)}`,
  boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.2)}`,
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1.5),
  padding: theme.spacing(2),
  background: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "translateX(8px)",
    background: alpha(theme.palette.background.paper, 0.5),
  },
}));

const VerifiedChip = styled(Chip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.success.main, 0.1),
  color: theme.palette.success.main,
  border: `1px solid ${alpha(theme.palette.success.main, 0.2)}`,
  "& .MuiChip-icon": {
    color: theme.palette.success.main,
  },
  height: 24,
  [theme.breakpoints.up("sm")]: {
    height: 32,
  },
}));

const UserInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  [theme.breakpoints.up("sm")]: {
    gap: theme.spacing(1),
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.default, 0.6),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1),
  "& .MuiSelect-select": {
    padding: theme.spacing(1.5),
  },
}));

const SettingsPage = () => {
  const { user, userData, refreshUserData } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedCurrency, setSelectedCurrency] = useState(
    userData?.settings?.defaultCurrency || "USD"
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    console.log('userData:', userData);
    if (userData?.settings?.defaultCurrency) {
      setSelectedCurrency(userData.settings.defaultCurrency);
    }
  }, [userData]);

  const handleCurrencyChange = async (event) => {
    const newCurrency = event.target.value;
    setSelectedCurrency(newCurrency);

    try {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        "settings.defaultCurrency": newCurrency,
      });
      setSnackbar({
        open: true,
        message: "Currency updated successfully",
        severity: "success",
      });
      if (refreshUserData) {
        refreshUserData();
      }
    } catch (error) {
      console.error("Error updating currency:", error);
      setSnackbar({
        open: true,
        message: "Failed to update currency",
        severity: "error",
      });
      setSelectedCurrency(userData?.settings?.defaultCurrency || "USD");
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const joinDate = user?.metadata?.creationTime
    ? new Date(user.metadata.creationTime).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : "N/A";

  return (
    <ResponsiveContainer>
      <Typography
        variant="h4"
        sx={{
          color: "primary.main",
          fontWeight: "bold",
          mb: { xs: 2, sm: 4 },
          fontSize: { xs: "1.5rem", sm: "1.75rem", md: "2rem" },
        }}
      >
        Settings
      </Typography>

      <ProfileCard>
        <ProfileHeader>
          <Box
            sx={{
              display: "flex",
              gap: { xs: 2, sm: 4 },
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <StyledAvatar
              src={user?.photoURL || "/api/placeholder/120/120"}
              alt={user?.displayName || "User Avatar"}
            />
            <UserInfo>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  flexWrap: "wrap",
                  justifyContent: isMobile ? "center" : "flex-start",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "text.primary",
                    fontWeight: 600,
                    fontSize: { xs: "1.25rem", sm: "1.5rem" },
                    textAlign: isMobile ? "center" : "left",
                  }}
                >
                  {user?.displayName || "User Name"}
                </Typography>
                {user?.emailVerified && (
                  <VerifiedChip
                    icon={
                      <VerifiedIcon sx={{ fontSize: isMobile ? 16 : 20 }} />
                    }
                    label="Verified"
                    size={isMobile ? "small" : "medium"}
                  />
                )}
              </Box>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  mb: 1,
                  textAlign: isMobile ? "center" : "left",
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                }}
              >
                Your personal account
              </Typography>
            </UserInfo>
          </Box>
        </ProfileHeader>

        <ProfileContent>
          <Typography
            variant="h6"
            sx={{
              color: "text.primary",
              fontWeight: 600,
              mb: 3,
              fontSize: { xs: "1.125rem", sm: "1.25rem" },
            }}
          >
            Account Information
          </Typography>

          <InfoItem>
            <EmailIcon
              sx={{
                color: "primary.main",
                fontSize: { xs: 20, sm: 24 },
              }}
            />
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
              >
                Email Address
              </Typography>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
              >
                {user?.email || "email@example.com"}
              </Typography>
            </Box>
          </InfoItem>

          <InfoItem>
            <CalendarIcon
              sx={{
                color: "secondary.main",
                fontSize: { xs: 20, sm: 24 },
              }}
            />
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
              >
                Member Since
              </Typography>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
              >
                {joinDate}
              </Typography>
            </Box>
          </InfoItem>
          <InfoItem>
            <CurrencyIcon
              sx={{
                color: "primary.main",
                fontSize: { xs: 20, sm: 24 },
              }}
            />
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
              >
                Default Currency
              </Typography>
              <FormControl fullWidth variant="outlined" size="small">
                <StyledSelect
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                >
                  {Object.entries(SUPPORTED_CURRENCIES).map(
                    ([code, details]) => (
                      <MenuItem key={code} value={code}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Typography>{details.symbol}</Typography>
                          <Typography>
                            {details.name} ({code})
                          </Typography>
                        </Box>
                      </MenuItem>
                    )
                  )}
                </StyledSelect>
              </FormControl>
            </Box>
          </InfoItem>
          <Typography
            variant="caption"
            sx={{
              display: "block",
              textAlign: "center",
              color: "text.secondary",
              mt: { xs: 3, sm: 4 },
              opacity: 0.7,
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
            }}
          >
            Additional account settings and preferences coming soon
          </Typography>
        </ProfileContent>
      </ProfileCard>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ResponsiveContainer>
  );
};

export default SettingsPage;
