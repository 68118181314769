import React from "react";
import { Box, Typography, Avatar, styled, alpha, Paper } from "@mui/material";
import { AttachMoney as MoneyIcon } from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";

const TreeContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  background: alpha(theme.palette.background.default, 0.5),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const ParticipantItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  marginBottom: theme.spacing(1),
  "&:last-child": {
    marginBottom: 0,
  },
}));

const AmountBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  marginLeft: "auto",
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius,
}));

const PayerBox = styled(ParticipantItem)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  marginBottom: theme.spacing(2),
}));

const ItemBreakdown = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(6),
  padding: theme.spacing(1),
  backgroundColor: alpha(theme.palette.background.paper, 0.2),
  borderRadius: theme.shape.borderRadius,
  "& > *:not(:last-child)": {
    marginBottom: theme.spacing(0.5),
  },
}));

const ItemRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.background.paper, 0.1),
  },
}));

const ReceiverSection = styled(Box)(({ theme }) => ({
  textAlign: "right",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
}));

const BillParticipantsTree = ({
  paidBy,
  participants,
  amount,
  splitType,
  shares = {},
  items = [],
}) => {
  const { userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || 'USD';

  const formatCurrency = (value) => {
    const num = parseFloat(value);
    if (isNaN(num))
      return new Intl.NumberFormat(
        SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
        {
          style: "currency",
          currency: currencyCode || "USD",
        }
      ).format(0);

    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
      }
    ).format(num);
  };

  const getParticipantItems = (participantId) => {
    if (!participantId || splitType !== "items" || !items?.length) return [];

    return items
      .filter((item) => item.participants?.includes(participantId))
      .map((item) => ({
        name: item.name,
        amount: parseFloat(
          (item.totalAmount / item.participants.length).toFixed(2)
        ),
      }));
  };

  // Find payer's share info
  const payerShare = shares[paidBy?.userId] || { original: 0, settlement: 0 };

  return (
    <TreeContainer>
      <Typography variant="subtitle1" color="primary.main" gutterBottom>
        Payment Distribution
      </Typography>

      {/* Payer's section */}
      <PayerBox>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Avatar
            src={paidBy?.photoURL || "/api/placeholder/32/32"}
            sx={{ width: 32, height: 32 }}
          />
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {paidBy?.displayName || paidBy?.email}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="caption" color="text.secondary">
                paid the total amount
              </Typography>
            </Box>
          </Box>
        </Box>
        <ReceiverSection>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: 0.5,
            }}
          >
            <Typography variant="caption" color="text.secondary">
              Paid
            </Typography>
            <AmountBox>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {formatCurrency(amount)}
              </Typography>
            </AmountBox>
          </Box>
          <Box
            sx={{
              mt: 1,
              py: 0.5,
              px: 1.5,
              bgcolor: alpha("#22C55E", 0.1),
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <Typography
              variant="caption"
              color="success.main"
              sx={{ fontWeight: 500 }}
            >
              Receives: {formatCurrency(Math.abs(payerShare.settlement))}
            </Typography>
          </Box>
        </ReceiverSection>
      </PayerBox>

      {/* Display items for payer if it's an item-based split */}
      {splitType === "items" && paidBy?.userId && (
        <ItemBreakdown>
          <Typography variant="caption" color="text.secondary" sx={{ pl: 1 }}>
            Your Items:
          </Typography>
          {getParticipantItems(paidBy.userId).map((item, index) => (
            <ItemRow key={index}>
              <Typography variant="caption">{item.name}</Typography>
              <Typography variant="caption" color="primary.main">
                {formatCurrency(item.amount)}
              </Typography>
            </ItemRow>
          ))}
        </ItemBreakdown>
      )}

      {/* Other participants */}
      {participants
        .filter((p) => p.userId !== paidBy?.userId)
        .map((participant) => {
          const participantShare = shares[participant.userId] || {
            original: 0,
            settlement: 0,
          };
          const participantItems = getParticipantItems(participant.userId);

          return (
            <Box key={participant.userId}>
              <ParticipantItem>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Avatar
                    src={participant?.photoURL || "/api/placeholder/32/32"}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {participant?.displayName || participant?.email}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      owes
                    </Typography>
                  </Box>
                </Box>
                <AmountBox>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {formatCurrency(participantShare.original)}
                  </Typography>
                </AmountBox>
              </ParticipantItem>

              {/* Show item breakdown for item-based splits */}
              {splitType === "items" && participantItems.length > 0 && (
                <ItemBreakdown>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ pl: 1 }}
                  >
                    Items:
                  </Typography>
                  {participantItems.map((item, index) => (
                    <ItemRow key={index}>
                      <Typography variant="caption">{item.name}</Typography>
                      <Typography variant="caption" color="primary.main">
                        {formatCurrency(item.amount)}
                      </Typography>
                    </ItemRow>
                  ))}
                  <ItemRow
                    sx={{
                      mt: 1,
                      pt: 1,
                      borderTop: (theme) =>
                        `1px dashed ${alpha(theme.palette.divider, 0.1)}`,
                    }}
                  >
                    <Typography variant="caption" fontWeight="bold">
                      Total Share
                    </Typography>
                    <Typography
                      variant="caption"
                      color="primary.main"
                      fontWeight="bold"
                    >
                      {formatCurrency(participantShare.original)}
                    </Typography>
                  </ItemRow>
                </ItemBreakdown>
              )}
            </Box>
          );
        })}

      {/* Total Amount Summary */}
      <Box
        sx={{
          mt: 3,
          pt: 2,
          borderTop: (theme) =>
            `1px dashed ${alpha(theme.palette.divider, 0.1)}`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2" color="text.secondary">
          Total Amount
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontWeight: 600 }}>
          {formatCurrency(amount)}
        </Typography>
      </Box>
    </TreeContainer>
  );
};

export default BillParticipantsTree;
