import React from "react";
import { Box, Typography, alpha } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { SUPPORTED_CURRENCIES } from "../../utils/currencyUtils";

const ItemDetails = ({ items, participantId }) => {
  const { user, userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || 'USD';
  const formatCurrency = (value) => {
    const num = parseFloat(value);
    if (isNaN(num)) return new Intl.NumberFormat(SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US", {
      style: "currency",
      currency: currencyCode,
    }).format(0);

    return new Intl.NumberFormat(SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US", {
      style: "currency",
      currency: currencyCode,
    }).format(num);
  };
  const participantItems = items.filter((item) =>
    item.participants.includes(participantId)
  );

  if (participantItems.length === 0) return null;

  return (
    <Box>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ display: "block", mb: 1 }}
      >
        Items you're splitting:
      </Typography>
      {participantItems.map((item, index) => (
        <Box
          key={index}
          sx={{
            p: 2,
            mb: 1,
            bgcolor: (theme) => alpha(theme.palette.background.paper, 0.4),
            borderRadius: 1,
            "&:last-child": { mb: 0 },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Box>
              <Typography variant="body2" fontWeight={500}>
                {item.name} × {item.quantity}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {formatCurrency(item.pricePerItem.toFixed(2))} per item
              </Typography>
            </Box>
            <Typography color="primary.main" fontWeight={500}>
              {formatCurrency(item.totalAmount.toFixed(2))}
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 1,
              pt: 1,
              borderTop: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography variant="caption" color="text.secondary">
              Your share: {formatCurrency(item.perPersonShare.toFixed(2))}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ display: "block" }}
            >
              Split between {item.participants.length} people
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ItemDetails;
