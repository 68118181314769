import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  browserSessionPersistence,
  browserLocalPersistence,
  setPersistence,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  getDocs,
  serverTimestamp,
  collection,
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  onSnapshot,
  addDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  writeBatch,
  Timestamp,
  increment,
} from "firebase/firestore";
import {
  getDatabase,
  ref,
  set,
  onDisconnect,
  onValue,
  serverTimestamp as rtdbServerTimestamp,
} from "firebase/database";
import { calculateGroupSettlements as calculateDetailedGroupSettlements } from "./groupSettlementsLogic";

const GROUP_LIMITS = {
  MAX_MEMBERS: 20,
  MIN_MEMBERS: 1,
  NAME_MIN_LENGTH: 3,
  NAME_MAX_LENGTH: 50,
};

const firebaseConfig = {
  apiKey: "AIzaSyCNlriwUZ7HxeGJqgjYZNDGxI_NNy31enA",
  authDomain: "wise-split-be.firebaseapp.com",
  projectId: "wise-split-be",
  storageBucket: "wise-split-be.firebasestorage.app",
  messagingSenderId: "1097969179249",
  appId: "1:1097969179249:web:dadeb50dfdeffbd431d9d0",
  measurementId: "G-Y7GKF597N0",
  databaseURL: "https://wise-split-be-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account",
});
export {
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  browserSessionPersistence,
  browserLocalPersistence,
  setPersistence,
};

export const db = getFirestore(app);
export const realtimeDb = getDatabase(app);

export const authPersistence = {
  SESSION: browserSessionPersistence,
  LOCAL: browserLocalPersistence,
};

auth.onAuthStateChanged((user) => {
  if (user) {
    console.log("Auth state changed: User is signed in");
  } else {
    console.log("Auth state changed: User is signed out");
  }
});

// User Management Functions
// Create or update user document in Firestore
export const createOrUpdateUser = async (user) => {
  if (!user) return null;

  const userRef = doc(db, "users", user.uid);
  const userSnap = await getDoc(userRef);

  if (!userSnap.exists()) {
    const userData = {
      userId: user.uid,
      displayName: user.displayName || "",
      email: user.email || "",
      photoURL: user.photoURL || "",
      createdAt: serverTimestamp(),
      lastActive: serverTimestamp(),
      isOnline: true,
      lastSeen: serverTimestamp(),
      settings: {
        defaultCurrency: "USD",
        notifications: true,
      },
      recentInteractions: [],
    };

    try {
      await setDoc(userRef, userData);
      return userData;
    } catch (error) {
      console.error("Error creating user document:", error);
      throw error;
    }
  } else {
    const existingData = userSnap.data();
    const userData = {
      ...existingData,
      userId: user.uid,
      displayName: user.displayName || existingData.displayName || "",
      email: user.email || existingData.email || "",
      photoURL: user.photoURL || existingData.photoURL || "",
      lastActive: serverTimestamp(),
      isOnline: true,
      lastSeen: serverTimestamp(),
      settings: {
        ...(existingData.settings || {}),
        defaultCurrency: existingData.settings?.defaultCurrency || "USD",
        notifications: existingData.settings?.notifications ?? true,
      },
      recentInteractions: existingData.recentInteractions || [],
    };

    try {
      await setDoc(userRef, userData);
      return userData;
    } catch (error) {
      console.error("Error updating user document:", error);
      throw error;
    }
  }
};

// User Search Functions
export const searchUsers = async (searchTerm, excludeUserIds = []) => {
  if (!searchTerm || searchTerm.length < 2) return [];

  const usersRef = collection(db, "users");

  try {
    const nameQuery = query(
      usersRef,
      where("displayName", ">=", searchTerm.toLowerCase()),
      where("displayName", "<=", searchTerm.toLowerCase() + "\uf8ff"),
      orderBy("displayName"),
      limit(5)
    );

    const emailQuery = query(
      usersRef,
      where("email", ">=", searchTerm.toLowerCase()),
      where("email", "<=", searchTerm.toLowerCase() + "\uf8ff"),
      orderBy("email"),
      limit(5)
    );

    const [nameSnapshot, emailSnapshot] = await Promise.all([
      getDocs(nameQuery),
      getDocs(emailQuery),
    ]);

    const results = new Map();

    nameSnapshot.forEach((doc) => {
      const userData = doc.data();
      if (!excludeUserIds.includes(userData.userId)) {
        results.set(userData.userId, userData);
      }
    });

    emailSnapshot.forEach((doc) => {
      const userData = doc.data();
      if (!excludeUserIds.includes(userData.userId)) {
        results.set(userData.userId, userData);
      }
    });

    return Array.from(results.values());
  } catch (error) {
    console.error("Error searching users:", error);
    throw new Error("Unable to search users. Please try again later.");
  }
};

// Initialize user presence system
export const initializePresence = async (userId) => {
  if (!userId) return;

  const userStatusRef = ref(realtimeDb, `/status/${userId}`);
  const userStatusFirestoreRef = doc(db, "users", userId);

  const connectionRef = ref(realtimeDb, ".info/connected");

  onValue(connectionRef, async (snapshot) => {
    if (snapshot.val() === false) {
      await updateDoc(userStatusFirestoreRef, {
        isOnline: false,
        lastSeen: serverTimestamp(),
      });
      return;
    }

    try {
      await onDisconnect(userStatusRef).set({
        isOnline: false,
        lastSeen: rtdbServerTimestamp(),
      });

      await Promise.all([
        set(userStatusRef, {
          isOnline: true,
          lastSeen: rtdbServerTimestamp(),
        }),
        updateDoc(userStatusFirestoreRef, {
          isOnline: true,
          lastSeen: serverTimestamp(),
        }),
      ]);
    } catch (error) {
      console.error("Error updating presence:", error);
    }
  });
};

// Get user's online status
export const getUserOnlineStatus = async (userId) => {
  if (!userId) return { isOnline: false, lastSeen: null };

  try {
    const userDoc = await getDoc(doc(db, "users", userId));
    if (!userDoc.exists()) return { isOnline: false, lastSeen: null };

    const userData = userDoc.data();
    return {
      isOnline: userData.isOnline || false,
      lastSeen: userData.lastSeen?.toDate() || null,
    };
  } catch (error) {
    console.error("Error getting user online status:", error);
    return { isOnline: false, lastSeen: null };
  }
};

// Subscribe to user's online status changes
export const subscribeToUserPresence = (userId, callback) => {
  if (!userId) return () => {};

  const userRef = doc(db, "users", userId);
  return onSnapshot(
    userRef,
    (doc) => {
      if (!doc.exists()) {
        callback({ isOnline: false, lastSeen: null });
        return;
      }

      const userData = doc.data();
      callback({
        isOnline: userData.isOnline || false,
        lastSeen: userData.lastSeen?.toDate() || null,
      });
    },
    (error) => {
      console.error("Error subscribing to presence:", error);
      callback({ isOnline: false, lastSeen: null });
    }
  );
};

// Recent Interactions Functions
export const updateRecentInteraction = async (
  currentUserId,
  interactedUserId
) => {
  if (!currentUserId || !interactedUserId || currentUserId === interactedUserId)
    return;

  try {
    const userRef = doc(db, "users", currentUserId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      let recentInteractions = userData.recentInteractions || [];

      recentInteractions = recentInteractions.filter(
        (id) => id !== interactedUserId
      );

      recentInteractions.unshift(interactedUserId);

      recentInteractions = recentInteractions.slice(0, 10);

      await updateDoc(userRef, {
        recentInteractions,
        lastActive: serverTimestamp(),
      });
    }
  } catch (error) {
    console.error("Error updating recent interactions:", error);
  }
};

export const getRecentlyInteractedUsers = async (userId) => {
  if (!userId) return [];

  try {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) return [];

    const userData = userSnap.data();
    const recentInteractions = userData.recentInteractions || [];

    const recentUsers = await Promise.all(
      recentInteractions.slice(0, 5).map(async (interactedUserId) => {
        const interactedUserRef = doc(db, "users", interactedUserId);
        const interactedUserSnap = await getDoc(interactedUserRef);

        if (interactedUserSnap.exists()) {
          return interactedUserSnap.data();
        }
        return null;
      })
    );

    return recentUsers.filter(Boolean);
  } catch (error) {
    console.error("Error getting recent interactions:", error);
    return [];
  }
};

// Group Management Functions
export const createGroup = async (data) => {
  try {
    const { name, createdBy, members = [] } = data;

    console.log("Creating group with data:", {
      name,
      createdBy,
      membersCount: members.length,
      members: members,
    });

    // Verify auth state
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User must be authenticated to create a group");
    }

    // Get creator's user data
    const creatorDoc = await getDoc(doc(db, "users", createdBy));
    if (!creatorDoc.exists()) {
      throw new Error("Creator user not found");
    }
    const creatorData = creatorDoc.data();

    const currentTime = Timestamp.now();

    // Initialize group data with creator as first member
    const groupData = {
      id: doc(collection(db, "groups")).id,
      name: name.trim(),
      createdBy,
      createdAt: currentTime,
      updatedAt: currentTime,
      members: [
        {
          userId: createdBy,
          role: "admin",
          addedAt: currentTime,
          displayName: creatorData.displayName || "",
          email: creatorData.email || "",
          photoURL: creatorData.photoURL || "",
          status: "active",
        },
      ],
      memberUserIds: [createdBy],
      status: "active",
    };

    // Add other members, excluding the creator if they're somehow in the members array
    const otherMembers = members.filter(
      (member) => member.userId !== createdBy
    );

    // Add filtered members to the group data
    otherMembers.forEach((member) => {
      groupData.members.push({
        userId: member.userId,
        role: "member",
        addedAt: currentTime,
        displayName: member.displayName || "",
        email: member.email || "",
        photoURL: member.photoURL || "",
        status: "active",
      });
      groupData.memberUserIds.push(member.userId);
    });

    // Check for duplicates in memberUserIds
    const uniqueMemberIds = [...new Set(groupData.memberUserIds)];
    if (uniqueMemberIds.length !== groupData.memberUserIds.length) {
      throw new Error("Duplicate members detected");
    }

    console.log("Final group data:", groupData);

    // Create the group document
    await setDoc(doc(db, "groups", groupData.id), groupData);

    return groupData.id;
  } catch (error) {
    console.error("Detailed error in createGroup:", error);
    throw error;
  }
};

export const getGroupById = async (groupId) => {
  try {
    const groupRef = doc(db, "groups", groupId);
    const groupSnap = await getDoc(groupRef);

    if (!groupSnap.exists()) {
      throw new Error("Group not found");
    }

    return {
      id: groupSnap.id,
      ...groupSnap.data(),
    };
  } catch (error) {
    console.error("Error getting group:", error);
    throw error;
  }
};

export const getUserGroups = async (userId) => {
  try {
    if (!userId) {
      throw new Error("User ID is required");
    }

    const groupsQuery = query(
      collection(db, "groups"),
      where("memberIds", "array-contains", userId),
      orderBy("createdAt", "desc")
    );

    const groupsSnap = await getDocs(groupsQuery);

    return groupsSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error getting user groups:", error);
    throw error;
  }
};

export const addMemberToGroup = async (groupId, newMember, addedBy) => {
  try {
    const groupRef = doc(db, "groups", groupId);

    await updateDoc(groupRef, {
      members: arrayUnion({
        userId: newMember.userId,
        addedBy: addedBy,
        addedAt: Timestamp.now(),
        displayName: newMember.displayName || "",
        email: newMember.email || "",
        photoURL: newMember.photoURL || "",
      }),
      memberIds: arrayUnion(newMember.userId),
      updatedAt: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error adding member to group:", error);
    throw error;
  }
};

export const removeMemberFromGroup = async (groupId, memberId) => {
  try {
    const groupRef = doc(db, "groups", groupId);
    const groupSnap = await getDoc(groupRef);

    if (!groupSnap.exists()) {
      throw new Error("Group not found");
    }

    const groupData = groupSnap.data();
    const memberToRemove = groupData.members.find((m) => m.userId === memberId);

    if (!memberToRemove) {
      throw new Error("Member not found in group");
    }

    await updateDoc(groupRef, {
      members: arrayRemove(memberToRemove),
      memberIds: arrayRemove(memberId),
      updatedAt: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error removing member from group:", error);
    throw error;
  }
};

export const deleteGroup = async (groupId) => {
  try {
    const groupRef = doc(db, "groups", groupId);
    await deleteDoc(groupRef);
  } catch (error) {
    console.error("Error deleting group:", error);
    throw error;
  }
};

export const subscribeToGroup = (groupId, callback) => {
  const groupRef = doc(db, "groups", groupId);

  return onSnapshot(
    groupRef,
    (doc) => {
      if (doc.exists()) {
        callback({
          id: doc.id,
          ...doc.data(),
        });
      } else {
        callback(null);
      }
    },
    (error) => {
      console.error("Error subscribing to group:", error);
      callback(null);
    }
  );
};

export const subscribeToUserGroups = (userId, callback) => {
  if (!userId) return () => {};

  const groupsQuery = query(
    collection(db, "groups"),
    where("memberUserIds", "array-contains", userId)
  );

  return onSnapshot(
    groupsQuery,
    (snapshot) => {
      // Sort groups by createdAt locally instead
      const groups = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => {
          const dateA = a.createdAt?.toMillis() || 0;
          const dateB = b.createdAt?.toMillis() || 0;
          return dateB - dateA; // Sort in descending order (newest first)
        });

      callback(groups);
    },
    (error) => {
      console.error("Error fetching groups:", error);
      callback([]);
    }
  );
};

// Bills Management Functions
export const createBill = async ({
  description,
  amount,
  paidBy,
  splitType,
  participants,
  createdBy,
  currency = "USD",
  category = "general",
  date,
  items = [],
  splitDetails = {},
  groupId = null, // Add groupId parameter
}) => {
  try {
    if (
      !description ||
      !amount ||
      !paidBy ||
      !splitType ||
      !participants ||
      !createdBy
    ) {
      throw new Error("Missing required fields for bill creation");
    }

    const batch = writeBatch(db);

    const updatedParticipants = participants.map((participant) => {
      const splitValue = splitDetails[participant.userId] || {};
      return {
        userId: String(participant.userId),
        displayName: String(participant.displayName || ""),
        email: String(participant.email || ""),
        photoURL: String(participant.photoURL || ""),
        share: {
          original: Number(participant.share.original),
          settlement:
            participant.userId === paidBy
              ? Number(participant.share.original) - Number(amount)
              : Number(participant.share.original),
        },
        splitValue: {
          percentage:
            splitType === "percentage"
              ? Number(splitValue.percentage || 0)
              : null,
          shares:
            splitType === "shares" ? Number(splitValue.shares || 0) : null,
        },
        shareType: String(splitType),
        settled: participant.settled || false,
        settledAt: participant.settledAt || null,
      };
    });

    const formattedItems = items.map((item) => ({
      name: String(item.name),
      quantity: Number(item.quantity),
      totalAmount: Number(item.totalAmount),
      participants: item.participants.map(String),
      perPersonShare: Number(item.perPersonShare),
      pricePerItem: Number(item.pricePerItem),
    }));

    const billRef = doc(collection(db, "bills"));

    const payerParticipant = updatedParticipants.find(
      (p) => p.userId === paidBy
    );

    const billData = {
      description: String(description),
      amount: Number(amount),
      paidBy: String(paidBy),
      splitType: String(splitType),
      participants: updatedParticipants,
      participantUserIds: participants.map((p) => String(p.userId)),
      createdBy: String(createdBy),
      currency: String(currency),
      category: String(category),
      items: splitType === "items" ? formattedItems : [],
      splitDetails:
        splitType === "percentage" || splitType === "shares"
          ? splitDetails
          : null,
      date: serverTimestamp(),
      status: "pending",
      createdAt: serverTimestamp(),
      lastUpdated: serverTimestamp(),
      groupId: groupId, // Add group ID if provided
      billType: groupId ? "group" : "personal", // Track bill type
    };

    batch.set(billRef, billData);

    // Create initial transaction for payer
    if (payerParticipant) {
      const transactionRef = doc(collection(db, "transactions"));
      const transactionData = {
        billId: billRef.id,
        userId: String(paidBy),
        amount: {
          original: Number(payerParticipant.share.original),
          settlement: Number(payerParticipant.share.settlement),
        },
        createdAt: serverTimestamp(),
        settledAt: serverTimestamp(),
        settledBy: String(paidBy),
        billDescription: String(description),
        type: "payment",
        status: "completed",
        groupId: groupId, // Add groupId to transaction as well
      };

      batch.set(transactionRef, transactionData);
    }

    await batch.commit();
    return billRef.id;
  } catch (error) {
    console.error("Error creating bill:", error);
    throw error;
  }
};

export const getBillsByGroup = async (groupId) => {
  try {
    const q = query(
      collection(db, "bills"),
      where("groupId", "==", groupId),
      orderBy("date", "desc")
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching group bills:", error);
    throw error;
  }
};

export const getUserBills = async (userId) => {
  try {
    if (!userId) {
      throw new Error("User ID is required");
    }

    console.log("Fetching bills for userId:", userId);

    // Update the query to include all bill statuses
    const q = query(
      collection(db, "bills"),
      where("participantUserIds", "array-contains", userId),
      where("status", "!=", "deleted")
    );

    const querySnapshot = await getDocs(q);
    console.log("Query snapshot size:", querySnapshot.size); // Debug log

    const bills = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      console.log("Processing bill:", doc.id, data); // Debug log
      return {
        id: doc.id,
        ...data,
      };
    });

    console.log("Processed bills:", bills); // Debug log
    return bills;
  } catch (error) {
    console.error("Error in getUserBills:", error);
    throw error;
  }
};

export const getUserNonGroupBills = async (userId) => {
  try {
    const q = query(
      collection(db, "bills"),
      where("groupId", "==", null),
      where("participants", "array-contains", { userId }),
      where("status", "==", "active"),
      orderBy("date", "desc")
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching user's non-group bills:", error);
    throw error;
  }
};

export const settleBillForUser = async (billId, userId) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const updatedParticipants = billData.participants.map((participant) => {
      if (participant.userId === userId) {
        return {
          ...participant,
          settled: true,
          settledAt: serverTimestamp(),
        };
      }
      return participant;
    });

    // Check if all participants have settled
    const allSettled = updatedParticipants.every(
      (participant) => participant.settled
    );

    await updateDoc(billRef, {
      participants: updatedParticipants,
      status: allSettled ? "settled" : "active",
    });
  } catch (error) {
    console.error("Error settling bill for user:", error);
    throw error;
  }
};

export const deleteBill = async (billId) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();

    // Only check for completed status
    if (billData.status === "completed") {
      throw new Error("Cannot delete a completed bill");
    }

    const batch = writeBatch(db);

    // Mark bill as deleted
    batch.update(billRef, {
      status: "deleted",
      deletedAt: serverTimestamp(),
      previousStatus: billData.status,
    });

    // Delete associated transactions
    const transactionsQuery = query(
      collection(db, "transactions"),
      where("billId", "==", billId)
    );
    const transactionsSnapshot = await getDocs(transactionsQuery);

    transactionsSnapshot.docs.forEach((transactionDoc) => {
      batch.delete(doc(db, "transactions", transactionDoc.id));
    });

    // Delete any associated settlements if this is a group bill
    if (billData.groupId) {
      const settlementsRef = doc(
        db,
        "groups",
        billData.groupId,
        "settlements",
        "current"
      );
      const settlementDoc = await getDoc(settlementsRef);

      if (settlementDoc.exists()) {
        const currentSettlements = settlementDoc.data().settlements || [];
        const updatedSettlements = currentSettlements.filter(
          (settlement) =>
            !billData.participants.some(
              (p) => p.userId === settlement.from || p.userId === settlement.to
            )
        );

        batch.update(settlementsRef, {
          settlements: updatedSettlements,
          updatedAt: serverTimestamp(),
        });
      }
    }

    await batch.commit();
    return { success: true };
  } catch (error) {
    console.error("Error deleting bill:", error);
    throw error;
  }
};

export const undeleteBill = async (billId) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const batch = writeBatch(db);

    // Restore bill to pending status
    batch.update(billRef, {
      status: "pending",
      deletedAt: null,
    });

    // Also update any related transactions if needed
    const transactionsQuery = query(
      collection(db, "transactions"),
      where("billId", "==", billId)
    );
    const transactionsSnapshot = await getDocs(transactionsQuery);

    transactionsSnapshot.docs.forEach((transactionDoc) => {
      batch.update(doc(db, "transactions", transactionDoc.id), {
        status: "pending",
      });
    });

    await batch.commit();
    return { success: true };
  } catch (error) {
    console.error("Error undeleting bill:", error);
    throw error;
  }
};

export const updateBill = async (billId, updateData) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    // Don't allow updating certain fields
    const safeUpdateData = {
      ...updateData,
    };

    delete safeUpdateData.createdBy;
    delete safeUpdateData.date;
    delete safeUpdateData.groupId;
    delete safeUpdateData.status;

    await updateDoc(billRef, safeUpdateData);
    return billId;
  } catch (error) {
    console.error("Error updating bill:", error);
    throw error;
  }
};

export const storeBillSettlements = async (billId, participants) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const batch = writeBatch(db);

    batch.update(billRef, {
      settlementStatus: "pending",
      settlementUpdatedAt: serverTimestamp(),
      participants: participants.map((participant) => ({
        userId: participant.userId,
        share: participant.share,
        shareType: participant.shareType,
        settled: false,
        settledAt: null,
        displayName: participant.displayName || "",
        email: participant.email || "",
        photoURL: participant.photoURL || "",
      })),
    });

    if (billData.groupId) {
      const groupRef = doc(db, "groups", billData.groupId);
      const totalUnsettled = participants.reduce((sum, p) => sum + p.share, 0);

      batch.update(groupRef, {
        totalUnsettledAmount: increment(totalUnsettled),
        updatedAt: serverTimestamp(),
      });
    }

    await batch.commit();
    return true;
  } catch (error) {
    console.error("Error storing bill settlements:", error);
    throw error;
  }
};

export const addSettlementHistory = async (groupId, settlement) => {
  try {
    const historyRef = collection(db, "groups", groupId, "settlementHistory");
    await addDoc(historyRef, {
      ...settlement,
      createdAt: serverTimestamp(),
      status: "completed",
    });
  } catch (error) {
    console.error("Error adding settlement history:", error);
    throw error;
  }
};

export const updateBillStatus = async (billId, status) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    await updateDoc(billRef, {
      status,
      updatedAt: serverTimestamp(),
    });

    const billData = billDoc.data();
    if (billData.groupId && status === "settled") {
      await calculateGroupSettlements(billData.groupId);
    }
  } catch (error) {
    console.error("Error updating bill status:", error);
    throw error;
  }
};

export const markSettlementComplete = async (billId, participantId, amount) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const batch = writeBatch(db);

    // Update participant's settlement status
    const updatedParticipants = billData.participants.map((p) =>
      p.userId === participantId
        ? {
            ...p,
            settled: true,
            settledAt: serverTimestamp(),
          }
        : p
    );

    // Check if all participants have settled
    const allSettled = updatedParticipants.every((p) => p.settled);

    // Update bill document
    batch.update(billRef, {
      participants: updatedParticipants,
      status: allSettled ? "completed" : "pending",
      lastUpdated: serverTimestamp(),
    });

    // Create transaction record
    const transactionRef = doc(collection(db, "transactions"));
    batch.set(transactionRef, {
      billId,
      userId: participantId,
      amount: {
        original: parseFloat(amount),
        settlement: parseFloat(amount),
      },
      type: "settlement",
      status: "completed",
      createdAt: serverTimestamp(),
      settledAt: serverTimestamp(),
    });

    await batch.commit();

    return {
      success: true,
      status: allSettled ? "completed" : "pending",
    };
  } catch (error) {
    console.error("Error in markSettlementComplete:", error);
    throw error;
  }
};

// Bill Settlements and Transactions
export const settleBillShare = async (billId, participantId, amount) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const currentTimestamp = Timestamp.now();

    // Create a new transaction
    const transactionData = {
      billId,
      userId: participantId,
      amount: {
        original: parseFloat(amount.original),
        settlement: parseFloat(amount.settlement),
      },
      createdAt: serverTimestamp(),
      settledAt: serverTimestamp(),
      settledBy: participantId,
      billDescription: billData.description,
      type: "settlement",
      status: "completed",
    };

    // Add the transaction
    const transactionRef = await addDoc(
      collection(db, "transactions"),
      transactionData
    );

    // Update the bill participants
    const updatedParticipants = billData.participants.map((p) => {
      if (p.userId === participantId) {
        return {
          ...p,
          settled: true,
          settledAt: currentTimestamp,
          transactionId: transactionRef.id,
        };
      }
      return p;
    });

    const allSettled = updatedParticipants.every((p) => p.settled);

    await updateDoc(billRef, {
      participants: updatedParticipants,
      status: allSettled ? "completed" : "pending",
      lastUpdated: serverTimestamp(),
    });

    return {
      success: true,
      transactionId: transactionRef.id,
      status: allSettled ? "completed" : "pending",
    };
  } catch (error) {
    console.error("Error in settleBillShare:", error);
    throw error;
  }
};

export const getUserTransactions = async (userId) => {
  try {
    const q = query(
      collection(db, "transactions"),
      where("userId", "==", userId),
      orderBy("settledAt", "desc")
    );

    const querySnapshot = await getDocs(q);

    // Get all transactions first
    const transactions = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Fetch associated bill details for each transaction
    const enrichedTransactions = await Promise.all(
      transactions.map(async (transaction) => {
        if (transaction.billId) {
          const billDoc = await getDoc(doc(db, "bills", transaction.billId));
          if (billDoc.exists()) {
            const billData = billDoc.data();
            return {
              ...transaction,
              billDetails: {
                splitType: billData.splitType,
                participants: billData.participants,
                paidBy: billData.paidBy,
                totalAmount: billData.amount,
                description: billData.description,
              },
            };
          }
        }
        return transaction;
      })
    );

    return enrichedTransactions;
  } catch (error) {
    console.error("Error fetching user transactions:", error);
    if (
      error.code === "failed-precondition" ||
      error.message.includes("requires an index")
    ) {
      throw new Error(
        "Setting up database indexes. Please try again in a few minutes."
      );
    }
    throw new Error("Failed to fetch transactions");
  }
};

export const getBillTransactions = async (billId) => {
  try {
    console.log("Fetching transactions for bill:", billId);

    const transactionsQuery = query(
      collection(db, "transactions"),
      where("billId", "==", billId),
      orderBy("createdAt", "desc")
    );

    const querySnapshot = await getDocs(transactionsQuery);
    const transactions = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log("Found transactions:", transactions);
    return transactions;
  } catch (error) {
    console.error("Error fetching bill transactions:", error);
    throw error;
  }
};

export const getUserBillTransactions = async (userId, billId) => {
  try {
    const q = query(
      collection(db, "transactions"),
      where("userId", "==", userId),
      where("billId", "==", billId),
      orderBy("settledAt", "desc")
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching user bill transactions:", error);
    throw new Error("Failed to fetch user bill transactions");
  }
};

// Helper function to calculate balances for a user
export const calculateUserBalances = async (userId) => {
  try {
    const bills = await getUserBills(userId);
    const balances = {};

    bills.forEach((bill) => {
      if (bill.paidBy === userId) {
        // Add amounts owed to the user
        bill.participants.forEach((participant) => {
          if (participant.userId !== userId && !participant.settled) {
            balances[participant.userId] = {
              original:
                (balances[participant.userId]?.original || 0) +
                participant.share.original,
              settlement:
                (balances[participant.userId]?.settlement || 0) +
                participant.share.settlement,
            };
          }
        });
      } else {
        // Subtract amounts the user owes
        const userParticipation = bill.participants.find(
          (p) => p.userId === userId
        );
        if (userParticipation && !userParticipation.settled) {
          balances[bill.paidBy] = {
            original:
              (balances[bill.paidBy]?.original || 0) -
              userParticipation.share.original,
            settlement:
              (balances[bill.paidBy]?.settlement || 0) -
              userParticipation.share.settlement,
          };
        }
      }
    });

    return balances;
  } catch (error) {
    console.error("Error calculating user balances:", error);
    throw error;
  }
};

export const calculateGroupSettlements = async (groupId) => {
  try {
    const settlements = await calculateDetailedGroupSettlements(groupId);
    return settlements;
  } catch (error) {
    console.error("Error in group settlements calculation:", error);
    throw error;
  }
};

export const getGroupSettlements = async (groupId) => {
  try {
    // First verify if the user has access to the group
    const groupRef = doc(db, "groups", groupId);
    const groupDoc = await getDoc(groupRef);

    if (!groupDoc.exists()) {
      throw new Error("Group not found");
    }

    // Get the settlements document
    const settlementsRef = doc(db, "groups", groupId, "settlements", "current");
    const settlementDoc = await getDoc(settlementsRef);

    // If no settlements document exists yet, return default empty state
    if (!settlementDoc.exists()) {
      return {
        settlements: [],
        status: "no_settlements",
        updatedAt: null,
        totalSettlements: 0,
      };
    }

    const data = settlementDoc.data();

    // Enrich settlement data with user details if not already present
    const enrichedSettlements = await Promise.all(
      (data.settlements || []).map(async (settlement) => {
        if (!settlement.fromUser || !settlement.toUser) {
          const [fromUserDoc, toUserDoc] = await Promise.all([
            getDoc(doc(db, "users", settlement.from)),
            getDoc(doc(db, "users", settlement.to)),
          ]);

          return {
            ...settlement,
            fromUser: fromUserDoc.exists()
              ? {
                  userId: fromUserDoc.id,
                  displayName: fromUserDoc.data().displayName,
                  email: fromUserDoc.data().email,
                  photoURL: fromUserDoc.data().photoURL,
                }
              : null,
            toUser: toUserDoc.exists()
              ? {
                  userId: toUserDoc.id,
                  displayName: toUserDoc.data().displayName,
                  email: toUserDoc.data().email,
                  photoURL: toUserDoc.data().photoURL,
                }
              : null,
          };
        }
        return settlement;
      })
    );

    return {
      settlements: enrichedSettlements,
      status: data.status || "active",
      updatedAt: data.updatedAt,
      totalSettlements: data.totalSettlements || enrichedSettlements.length,
    };
  } catch (error) {
    console.error("Error fetching group settlements:", error);
    // Return empty state instead of throwing error
    return {
      settlements: [],
      status: "error",
      updatedAt: null,
      totalSettlements: 0,
    };
  }
};

export const markGroupSettlementComplete = async (
  groupId,
  fromUserId,
  toUserId,
  amount
) => {
  try {
    const settlementsRef = doc(db, "groups", groupId, "settlements", "current");
    const groupRef = doc(db, "groups", groupId);

    // Get both settlements and group data in parallel
    const [settlementDoc, groupDoc] = await Promise.all([
      getDoc(settlementsRef),
      getDoc(groupRef),
    ]);

    if (!settlementDoc.exists()) {
      throw new Error("Group settlements not found");
    }

    if (!groupDoc.exists()) {
      throw new Error("Group not found");
    }

    const data = settlementDoc.data();
    const groupData = groupDoc.data();
    const settlements = data.settlements || [];

    // Find the specific settlement
    const settlementIndex = settlements.findIndex(
      (s) =>
        s.from === fromUserId &&
        s.to === toUserId &&
        Math.abs(s.amount - amount) < 0.01
    );

    if (settlementIndex === -1) {
      throw new Error("Settlement not found");
    }

    const batch = writeBatch(db);
    const completedSettlement = settlements[settlementIndex];
    const currentTimestamp = serverTimestamp();

    // 1. Create Settlement History Record
    const historyRef = doc(collection(db, "settlementHistory"));
    const historyData = {
      ...completedSettlement,
      groupId,
      groupName: groupData.name,
      completedAt: currentTimestamp,
      status: "completed",
      settledBy: fromUserId,
      type: "group_settlement",
      historyId: historyRef.id,
      lastUpdated: currentTimestamp,
      totalAmount: amount,
      relatedBills: completedSettlement.relatedBills || {},
    };
    batch.set(historyRef, historyData);

    // 2. Update Bills and Create Transactions
    const transactionPromises = [];
    const billUpdates = new Map();

    if (completedSettlement.relatedBills?.debtorBills) {
      for (const billInfo of completedSettlement.relatedBills.debtorBills) {
        const billRef = doc(db, "bills", billInfo.billId);
        const billDoc = await getDoc(billRef);

        if (billDoc.exists()) {
          const billData = billDoc.data();

          // Update bill participants
          const updatedParticipants = billData.participants.map((p) => {
            if (p.userId === fromUserId) {
              return {
                ...p,
                settled: true,
                settledAt: currentTimestamp,
              };
            }
            return p;
          });

          const allSettled = updatedParticipants.every(
            (p) => p.settled || p.userId === billData.paidBy
          );

          // Store bill updates
          billUpdates.set(billRef.id, {
            ref: billRef,
            data: {
              participants: updatedParticipants,
              status: allSettled ? "completed" : "pending",
              lastUpdated: currentTimestamp,
            },
          });

          // 3. Create Transaction Records (one for debtor, one for creditor)
          // Debtor Transaction (negative amount)
          const debtorTransactionRef = doc(collection(db, "transactions"));
          const debtorTransaction = {
            billId: billInfo.billId,
            groupId,
            userId: fromUserId,
            amount: {
              original: -billInfo.amount,
              settlement: -billInfo.amount,
            },
            createdAt: currentTimestamp,
            settledAt: currentTimestamp,
            settledBy: fromUserId,
            billDescription: billInfo.description || "Group Bill Payment",
            type: "payment",
            status: "completed",
            settlementHistoryId: historyRef.id,
          };
          transactionPromises.push({
            ref: debtorTransactionRef,
            data: debtorTransaction,
          });

          // Creditor Transaction (positive amount)
          const creditorTransactionRef = doc(collection(db, "transactions"));
          const creditorTransaction = {
            billId: billInfo.billId,
            groupId,
            userId: toUserId,
            amount: {
              original: billInfo.amount,
              settlement: billInfo.amount,
            },
            createdAt: currentTimestamp,
            settledAt: currentTimestamp,
            settledBy: fromUserId,
            billDescription: billInfo.description || "Group Bill Receipt",
            type: "receipt",
            status: "completed",
            settlementHistoryId: historyRef.id,
          };
          transactionPromises.push({
            ref: creditorTransactionRef,
            data: creditorTransaction,
          });
        }
      }
    }

    // 4. Update Bills in Batch
    billUpdates.forEach((update) => {
      batch.update(update.ref, update.data);
    });

    // 5. Add Transactions to Batch
    transactionPromises.forEach((transaction) => {
      batch.set(transaction.ref, transaction.data);
    });

    // 6. Update Group Stats
    const groupStats = {
      lastSettlementAt: currentTimestamp,
      totalSettlements: increment(1),
      activeSettlements: increment(-1),
      settledAmount: increment(amount),
    };
    batch.update(groupRef, groupStats);

    // 7. Update Current Settlements List
    const updatedSettlements = settlements.filter(
      (_, i) => i !== settlementIndex
    );
    batch.update(settlementsRef, {
      settlements: updatedSettlements,
      updatedAt: currentTimestamp,
      status: updatedSettlements.length === 0 ? "completed" : "active",
      totalSettlements: (data.totalSettlements || 0) + 1,
      completedSettlements: (data.completedSettlements || 0) + 1,
    });

    // Commit all changes
    await batch.commit();

    return {
      success: true,
      status: updatedSettlements.length === 0 ? "completed" : "active",
      settlementHistoryId: historyRef.id,
      updatedBills: Array.from(billUpdates.keys()),
      transactionCount: transactionPromises.length,
    };
  } catch (error) {
    console.error("Error in markGroupSettlementComplete:", error);
    throw error;
  }
};

export const verifySettlement = async (billId, participantId) => {
  try {
    const billRef = doc(db, "bills", billId);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const participant = billData.participants.find(
      (p) => p.userId === participantId
    );

    return participant?.settled === true;
  } catch (error) {
    console.error("Error verifying settlement:", error);
    throw error;
  }
};

// Helper function to check if a bill is a group bill
export const isGroupBill = (bill) => {
  return bill?.groupId != null;
};

export const handleSettlementComplete = async (bill, settlement) => {
  try {
    const billRef = doc(db, "bills", bill.id);
    const billDoc = await getDoc(billRef);

    if (!billDoc.exists()) {
      throw new Error("Bill not found");
    }

    const billData = billDoc.data();
    const batch = writeBatch(db);
    const now = new Date(); // Use regular Date object for nested data

    // Sanitize participant data by removing Firestore timestamps
    const sanitizeParticipantData = (participant) => {
      const sanitized = {
        ...participant,
        settledAt: null,  // Reset the settledAt timestamp
        share: participant.share || { original: 0, settlement: 0 }
      };
      
      // Remove any Firestore-specific fields
      delete sanitized.timestamp;
      delete sanitized.lastUpdated;
      
      return sanitized;
    };

    // 1. Update participant's settlement status with the current timestamp
    const updatedParticipants = billData.participants.map(p => {
      if (p.userId === settlement.from) {
        return {
          ...sanitizeParticipantData(p),
          settled: true,
          settledAt: now  // Use Date object here
        };
      }
      return sanitizeParticipantData(p);
    });

    // 2. Check if all participants have settled
    const allSettled = updatedParticipants.every(p => 
      p.settled || p.userId === billData.paidBy
    );

    // 3. Create transaction record with sanitized data
    const transactionRef = doc(collection(db, "transactions"));
    const transactionData = {
      billId: bill.id,
      userId: settlement.from,
      type: "payment",
      status: "completed",
      amount: {
        original: parseFloat(settlement.amount),
        settlement: parseFloat(settlement.amount)
      },
      billDescription: billData.description || "Bill Payment",
      createdAt: now,  // Use Date object
      settledAt: now,  // Use Date object
      settledBy: settlement.from,
      groupId: bill.groupId || null,
      billDetails: {
        paidBy: billData.paidBy,
        totalAmount: billData.amount,
        splitType: billData.splitType,
        participants: billData.participants.map(p => ({
          userId: p.userId,
          displayName: p.displayName || '',
          email: p.email || '',
          photoURL: p.photoURL || '',
          share: p.share || { original: 0, settlement: 0 },
          settled: p.settled || false
        }))
      }
    };

    // 4. Prepare bill update without serverTimestamp in arrays
    const billUpdate = {
      participants: updatedParticipants.map(p => ({
        ...p,
        settledAt: p.settledAt ? p.settledAt : null
      })),
      status: allSettled ? "completed" : "pending",
      lastUpdated: now  // Use Date object
    };

    // 5. Add operations to batch with proper timestamp handling
    batch.update(billRef, {
      participants: billUpdate.participants,
      status: billUpdate.status,
      lastUpdated: serverTimestamp()  // Only use serverTimestamp for top-level fields
    });
    
    batch.set(transactionRef, {
      ...transactionData,
      createdAt: serverTimestamp(),  // Convert to serverTimestamp for top-level fields
      settledAt: serverTimestamp()   // Convert to serverTimestamp for top-level fields
    });

    // 6. Handle group updates if needed
    if (bill.groupId && allSettled) {
      const groupRef = doc(db, "groups", bill.groupId);
      batch.update(groupRef, {
        lastSettlementAt: serverTimestamp(),  // Safe to use serverTimestamp here
        totalSettlements: increment(1),
        settledAmount: increment(settlement.amount)
      });
    }

    // 7. Commit the batch
    await batch.commit();

    return { 
      success: true,
      status: allSettled ? "completed" : "pending",
      transactionId: transactionRef.id
    };
  } catch (error) {
    console.error("Error in handleSettlementComplete:", error);
    throw error;
  }
};
