import React, { createContext, useContext, useEffect, useState } from "react";
import {
  auth,
  createOrUpdateUser,
  initializePresence,
  db,
} from "../backend-services/firebase";
import { doc,onSnapshot } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import {
  getTokenFromStorage,
  removeTokenFromStorage,
  setTokenInStorage,
} from "../utils/auth";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(getTokenFromStorage());

  const refreshUserData = async () => {
    if (!user?.uid) return null;

    try {
      console.log("Refreshing user data...");
      const firestoreData = await createOrUpdateUser({
        ...user,
        lastActive: new Date(),
      });
      console.log("Updated user data:", firestoreData);
      setUserData(firestoreData);
      return firestoreData;
    } catch (error) {
      console.error("Error refreshing user data:", error);
      throw error;
    }
  };

  // Subscribe to user document changes
  useEffect(() => {
    if (!user?.uid) return;

    const userRef = doc(db, "users", user.uid);
    const unsubscribe = onSnapshot(
      userRef,
      (doc) => {
        if (doc.exists()) {
          console.log("User data updated:", doc.data());
          setUserData(doc.data());
        }
      },
      (error) => {
        console.error("Error listening to user data:", error);
      }
    );

    return () => unsubscribe();
  }, [user?.uid]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        try {
          const idToken = await user.getIdToken();
          setToken(idToken);
          setTokenInStorage(idToken);
          const firestoreData = await createOrUpdateUser(user);
          setUserData(firestoreData);
          await initializePresence(user.uid);
        } catch (error) {
          console.error("Error syncing user data with Firestore:", error);
        }
      } else {
        setUserData(null);
        setToken(null);
        removeTokenFromStorage();
      }
      setLoading(false);
    });
    const tokenRefreshInterval = setInterval(async () => {
      if (auth.currentUser) {
        try {
          const newToken = await auth.currentUser.getIdToken(true);
          setToken(newToken);
          setTokenInStorage(newToken);
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      }
    }, 45 * 60 * 1000);
    return () => {
      unsubscribe();
      clearInterval(tokenRefreshInterval);
    };
  }, []);

  const value = {
    user,
    loading,
    userData,
    setToken,
    refreshUserData,
    token,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
