import React from "react";
import {
  Box,
  Typography,
  LinearProgress,
  useTheme,
  alpha,
  styled,
  Avatar,
  Chip,
} from "@mui/material";
import { motion } from "framer-motion";
import {
  AccountBalance,
  CheckCircle as CheckCircleIcon,
  Pending as PendingIcon,
  Payments as PaymentsIcon,
  AccountBalanceWallet as WalletIcon,
  Groups as GroupsIcon,
} from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";

// Greeting Card - Simple and Clean
const GreetingCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.main,
    0.15
  )} 0%, ${alpha(theme.palette.secondary.main, 0.15)} 100%)`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  "& h4": {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  "& p": {
    color: alpha(theme.palette.text.primary, 0.7),
  },
}));

// Compact Stats Grid
const StatsGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(2),
  gridTemplateColumns: "1fr",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
}));

// Stats Card
const StatsCard = styled(motion.div)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.4),
  backdropFilter: "blur(8px)",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
  },
}));

// Header Row with Icon
const CardHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 8,
  marginBottom: 12,
});

// User Bill Card Components
const UserListSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const UserListItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.3),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  "&:last-child": {
    marginBottom: 0,
  },
}));

const UserAmount = styled(Box)(({ theme, isPositive }) => ({
  marginLeft: "auto",
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(
    isPositive ? theme.palette.success.main : theme.palette.error.main,
    0.1
  ),
  color: isPositive ? theme.palette.success.main : theme.palette.error.main,
}));

const getTimeBasedGreeting = () => {
  const hour = new Date().getHours();
  if (hour >= 0 && hour < 4) return "Hello";
  if (hour < 12) return "Good morning";
  if (hour < 17) return "Good afternoon";
  return "Good evening";
};

const DashboardStats = ({ stats }) => {
  const theme = useTheme();
  const greeting = getTimeBasedGreeting();
  const { user, userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || "USD";

  const formatCurrency = (value) => {
    const num = parseFloat(value);
    if (isNaN(num))
      return new Intl.NumberFormat(
        SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
        {
          style: "currency",
          currency: currencyCode || "USD",
        }
      ).format(0);

    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
      }
    ).format(num);
  };

  return (
    <>
      {/* Simplified Greeting */}
      <GreetingCard>
        <Typography variant="h4">
          {greeting}, {user?.displayName || "there"}! 👋
        </Typography>
        <Typography variant="body1">
          Track your shared expenses and instant updates
        </Typography>
      </GreetingCard>

      {/* Compact Stats Cards */}
      <StatsGrid>
        <StatsCard>
          <CardHeader>
            <PaymentsIcon sx={{ color: "secondary.main" }} />
            <Typography variant="subtitle1" color="text.secondary">
              Total Spends
            </Typography>
          </CardHeader>
          <Typography variant="h4" color="secondary.main" fontWeight="600">
            {formatCurrency(stats?.totalSpends || 0)}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Lifetime Spending
          </Typography>
        </StatsCard>

        <StatsCard>
          <CardHeader>
            <WalletIcon
              sx={{
                color: stats?.totalToPay > 0 ? "error.main" : "text.secondary",
              }}
            />
            <Typography variant="subtitle1" color="text.secondary">
              You Pay
            </Typography>
          </CardHeader>
          <Typography
            variant="h4"
            color={stats?.totalToPay > 0 ? "error.main" : "text.secondary"}
            fontWeight="600"
          >
            {formatCurrency(stats?.totalToPay || 0)}
          </Typography>
          {stats?.totalToPay > 0 ? (
            <Chip
              label="Payment Required"
              size="small"
              color="error"
              variant="outlined"
            />
          ) : (
            <Chip
              label="No Pending Payments"
              size="small"
              color="success"
              variant="outlined"
            />
          )}
        </StatsCard>

        <StatsCard>
          <CardHeader>
            <AccountBalance
              sx={{
                color:
                  stats?.totalReceivables > 0
                    ? "success.main"
                    : "text.secondary",
              }}
            />
            <Typography variant="subtitle1" color="text.secondary">
              You Get
            </Typography>
          </CardHeader>
          <Typography
            variant="h4"
            color={
              stats?.totalReceivables > 0 ? "success.main" : "text.secondary"
            }
            fontWeight="600"
          >
            {formatCurrency(stats?.totalReceivables || 0)}
          </Typography>
          {stats?.totalReceivables > 0 ? (
            <Chip
              label="Amount Pending"
              size="small"
              color="primary"
              variant="outlined"
            />
          ) : (
            <Chip
              label="All Settled"
              size="small"
              color="success"
              variant="outlined"
            />
          )}
        </StatsCard>

        <StatsCard>
          <CardHeader>
            <Box component="span" sx={{ color: "primary.main" }}>
              {stats?.settledBills || 0}/{stats?.totalBills || 0}
            </Box>
            <Typography variant="subtitle1" color="text.secondary">
              Bills Settled
            </Typography>
          </CardHeader>
          <LinearProgress
            variant="determinate"
            value={stats?.settlementProgress || 0}
            sx={{
              height: 8,
              borderRadius: 4,
              bgcolor: alpha(theme.palette.primary.main, 0.1),
              "& .MuiLinearProgress-bar": {
                borderRadius: 4,
              },
            }}
          />
          <Typography variant="caption" color="text.secondary">
            {stats?.pendingBills || 0} bills pending
          </Typography>
        </StatsCard>
      </StatsGrid>

      {/* User Bills Section */}
      {stats?.userStats?.length > 0 && (
        <UserListSection>
          <Box sx={{ mb: 3, display: "flex", alignItems: "center", gap: 1 }}>
            <WalletIcon
              color={
                stats.userStats.some((stat) => stat.amountOwed > 0)
                  ? "error"
                  : "success"
              }
            />
            {stats.userStats.some((stat) => stat.amountOwed > 0) && "You Owe"}
            {stats?.totalBills > 0 &&
              !stats?.needsSettlement &&
              stats?.totalToPay === 0 &&
              stats?.totalReceivables === 0 && (
                <Box
                  sx={{
                    mt: 3,
                    p: 2,
                    bgcolor: alpha(theme.palette.success.main, 0.1),
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="success.main"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <CheckCircleIcon /> You're all settled
                  </Typography>
                </Box>
              )}
          </Box>

          {stats.userStats
            .filter((stat) => stat.amountOwed > 0)
            .map((stat) => (
              <UserListItem key={stat.user.userId}>
                <Avatar
                  src={stat.user.photoURL || "/api/placeholder/40/40"}
                  sx={{ width: 40, height: 40, mr: 2 }}
                />
                <Box>
                  <Typography variant="subtitle1">
                    {stat.user.displayName}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {stat.billsCount} shared bills
                  </Typography>
                </Box>
                <UserAmount isPositive={false}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {formatCurrency(stat.amountOwed)}
                  </Typography>
                </UserAmount>
              </UserListItem>
            ))}
        </UserListSection>
      )}
    </>
  );
};

export default DashboardStats;
