import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  useTheme,
  alpha,
  styled,
  Chip,
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  AssignmentTurnedIn as ViewSettlementsIcon,
} from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import SettlementDrawer from "./settlement/SettlementDrawer";
import { SUPPORTED_CURRENCIES } from "../utils/currencyUtils";
import { deleteBill, undeleteBill } from "../backend-services/firebase";
import { motion, AnimatePresence } from "framer-motion";

const CardWrapper = styled(motion.div)(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  borderRadius: "12px 12px 0 0",
  overflow: "visible",
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  backdropFilter: "blur(10px)",
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  borderBottom: "none",
  maxWidth: "100%",

  // Improved notched line
  "& .notch-line": {
    position: "relative",
    width: "100%",
    height: "28px", // Increased from 20px
    marginTop: theme.spacing(2),

    // Thicker dashed line
    "&::before": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: "24px", // Increased space for larger notch
      right: "24px", // Increased space for larger notch
      borderTop: `2px dashed ${alpha(theme.palette.divider, 0.2)}`, // Increased from 1px
    },

    // Larger left notch
    "&::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: "24px", // Increased from 20px
      height: "24px", // Increased from 20px
      borderRadius: "50%",
      background: theme.palette.background.default,
      left: -12,
      boxShadow: `1px 1px 2px ${alpha(theme.palette.common.black, 0.2)} inset`,
    },
  },

  // Right notch as separate element for cleaner implementation
  "& .right-notch": {
    content: '""',
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: "24px", // Increased from 20px
    height: "24px", // Increased from 20px
    right: -12,
    borderRadius: "50%",
    background: theme.palette.background.default,
    boxShadow: `1px 1px 2px ${alpha(theme.palette.common.black, 0.2)} inset`,
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderBottom: `2px dashed ${alpha(theme.palette.divider, 0.1)}`,
  textAlign: "center", // Center aligned content
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const BillTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 600,
  color: theme.palette.primary.main,
  position: "relative",
  padding: `${theme.spacing(1)} 0`,
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "40px",
    height: "2px",
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
  },
}));

const BillDate = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  color: alpha(theme.palette.text.secondary, 0.8),
  marginBottom: theme.spacing(1),
}));

const StatusContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  justifyContent: "center",
  marginTop: theme.spacing(1),
}));

const ParticipantsSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  "& .section-header": {
    marginBottom: theme.spacing(2),
    position: "relative",
    paddingBottom: theme.spacing(2),
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: -24,
      right: -24,
      borderBottom: `2px dashed ${alpha(theme.palette.divider, 0.1)}`,
    },
  },
}));

const PayerRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start", // Changed from center to flex-start
  justifyContent: "space-between",
  padding: theme.spacing(2, 0),
  borderBottom: `2px dashed ${alpha(theme.palette.divider, 0.1)}`,
  marginBottom: theme.spacing(2),
}));

const ReceivesAmount = styled(Box)(({ theme }) => ({
  textAlign: "right",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
}));

const ParticipantRow = styled(Box)(({ theme, isPayer }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1.5, 0),
  marginBottom: theme.spacing(1),
  "&:last-child": {
    marginBottom: 0,
  },
}));

const ParticipantInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1.5),
}));

const StatusChip = styled(Chip)(({ theme, $status }) => ({
  fontSize: "0.75rem",
  fontWeight: 500,
  height: 24,
  "& .MuiChip-label": {
    padding: "0 8px",
  },
  backgroundColor: (() => {
    switch ($status) {
      case "completed":
        return alpha(theme.palette.success.main, 0.1);
      case "partially_settled":
        return alpha(theme.palette.warning.main, 0.1);
      case "active":
      default:
        return alpha(theme.palette.info.main, 0.1);
    }
  })(),
  color: (() => {
    switch ($status) {
      case "completed":
        return theme.palette.success.main;
      case "pending":
        return theme.palette.warning.main;
      default:
        return theme.palette.info.main;
    }
  })(),
}));

const SplitTypeChip = styled(Chip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.text.secondary,
  fontSize: "0.75rem",
  fontWeight: 500,
  height: 24,
  "& .MuiChip-label": {
    padding: "0 8px",
  },
}));

const ActionMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: "blur(8px)",
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    borderRadius: theme.shape.borderRadius,
    minWidth: 150,
  },
}));

const MenuIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.text.secondary,
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
  },
}));

const DeleteMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  "&:hover": {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
  },
}));

const DeleteDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: "blur(10px)",
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    maxWidth: "400px",
  },
}));

const DialogButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  ...(variant === "delete" && {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: alpha(theme.palette.error.main, 0.8),
    },
  }),
}));

const UndoSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiSnackbarContent-root": {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: "blur(8px)",
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    color: theme.palette.text.primary,
  },
}));

const SnackbarButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));

const BillCard = ({ bill, onBillUpdate }) => {
  const { userData } = useAuth();
  const currencyCode = userData?.settings?.defaultCurrency || "USD";
  const theme = useTheme();
  const { user } = useAuth();
  const [isSettlementOpen, setIsSettlementOpen] = useState(false);
  const paidByUser = bill.participants.find((p) => p.userId === bill.paidBy);
  const isCreator = bill.createdBy === user?.uid;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showUndoSnackbar, setShowUndoSnackbar] = useState(false);
  const [deleteTimeout, setDeleteTimeout] = useState(null);
  const hasPendingSettlements = bill.participants.some(
    (p) => !p.settled && p.userId !== bill.paidBy
  );

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleDeleteConfirm = async () => {
    // Check if bill can be deleted
    if (bill.status === "completed") {
      setDeleteError("Cannot delete a completed bill");
      return;
    }

    try {
      setIsDeleting(true);
      setDeleteError(null);
      await deleteBill(bill.id);

      setIsDeleteDialogOpen(false);
      setIsDeleted(true);
      setShowUndoSnackbar(true);

      // Set a timeout to finalize deletion
      const timeout = setTimeout(() => {
        if (onBillUpdate) {
          onBillUpdate();
        }
        setShowUndoSnackbar(false);
      }, 6000); // 6 seconds for undo

      setDeleteTimeout(timeout);
    } catch (error) {
      console.error("Error deleting bill:", error);
      setDeleteError(error.message || "Failed to delete bill");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUndo = async () => {
    try {
      if (deleteTimeout) {
        clearTimeout(deleteTimeout);
      }
      setIsDeleting(true);
      await undeleteBill(bill.id);
      setIsDeleted(false);
      setShowUndoSnackbar(false);
      if (onBillUpdate) {
        onBillUpdate();
      }
    } catch (error) {
      setDeleteError(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
    setDeleteError(null);
  };

  const handleMenuClose = (event) => {
    event?.stopPropagation();
    setMenuAnchorEl(null);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    handleMenuClose();
    setIsDeleteDialogOpen(true);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat(
      SUPPORTED_CURRENCIES[currencyCode]?.locale || "en-US",
      {
        style: "currency",
        currency: currencyCode || "USD",
      }
    ).format(Math.abs(value));
  };
  const formatDate = (timestamp) => {
    if (!timestamp) return "";

    // Handle Firestore timestamp
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);

    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const getButtonConfig = () => {
    if (!user || !bill) return null;

    const currentUserParticipant = bill.participants.find(
      (p) => p.userId === user.uid
    );

    // If bill is completed/settled
    if (bill.status === "completed") {
      return {
        label: "View Details", // Changed from "View Status"
        icon: <CheckCircleIcon />,
        color: "primary",
        action: handleSettleClick,
        customStyle: {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          color: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
          },
        },
      };
    }

    // If user is the bill payer
    if (user.uid === bill.paidBy) {
      return {
        label: "View Settlements",
        icon: <ViewSettlementsIcon />,
        color: "primary",
        action: handleSettleClick,
        customStyle: {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          color: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
          },
        },
      };
    }

    // If user is a participant
    if (currentUserParticipant) {
      if (currentUserParticipant.settled) {
        return {
          label: "View Details", // Changed from "View Status"
          icon: <CheckCircleIcon />,
          color: "primary",
          action: handleSettleClick,
          customStyle: {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            color: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
            },
          },
        };
      } else {
        return {
          label: "Settle Bill",
          icon: <CheckCircleIcon />,
          color: "primary",
          action: handleSettleClick,
          customStyle: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.black,
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.8),
            },
          },
        };
      }
    }

    return null;
  };
  const handleCloseSettlement = () => {
    setIsSettlementOpen(false);
  };
  const handleSettleClick = () => {
    setIsSettlementOpen(true);
  };

  useEffect(() => {
    return () => {
      if (deleteTimeout) {
        clearTimeout(deleteTimeout);
      }
    };
  }, [deleteTimeout]);

  return (
    <>
      <AnimatePresence>
        {!isDeleted && (
          <CardWrapper>
            <CardHeader>
              {isCreator && (
                <>
                  <MenuIconButton
                    aria-label="bill actions"
                    onClick={handleMenuOpen}
                    size="small"
                  >
                    <MoreVertIcon />
                  </MenuIconButton>
                  <ActionMenu
                    anchorEl={menuAnchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <DeleteMenuItem onClick={handleDeleteClick}>
                      <DeleteIcon sx={{ mr: 1, fontSize: 20 }} />
                      Delete Bill
                    </DeleteMenuItem>
                  </ActionMenu>
                </>
              )}
              <BillTitle variant="h6">{bill.description}</BillTitle>
              <BillDate>
                {formatDate(bill.date) || "No date available"}
              </BillDate>
              <StatusContainer>
                <SplitTypeChip
                  label={`Split: ${bill.splitType}`}
                  size="small"
                />
                <StatusChip
                  label={bill.status === "completed" ? "Settled" : "Pending"}
                  $status={bill.status}
                />
              </StatusContainer>
            </CardHeader>

            <ParticipantsSection>
              <Box className="section-header">
                <Typography variant="subtitle2" color="text.secondary">
                  Participants
                </Typography>
              </Box>

              {/* Payer Row */}
              <PayerRow>
                <ParticipantInfo>
                  <Avatar
                    src={paidByUser?.photoURL || "/api/placeholder/32/32"}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Box>
                    <Typography variant="body2" fontWeight={500}>
                      {paidByUser?.displayName || paidByUser?.email}
                    </Typography>
                    <Typography variant="caption" color="success.main">
                      Paid Full Amount
                    </Typography>
                  </Box>
                </ParticipantInfo>
                <ReceivesAmount>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ fontWeight: 500 }}
                  >
                    Receives
                  </Typography>
                  <Typography
                    variant="body2"
                    color="success.main"
                    fontWeight={600}
                  >
                    {formatCurrency(
                      Math.abs(paidByUser?.share?.settlement || 0).toFixed(2)
                    )}
                  </Typography>
                </ReceivesAmount>
              </PayerRow>

              {/* All Participants (including payer) */}
              {bill.participants.map((participant) => {
                const isPayer = participant.userId === bill.paidBy;
                const isSettled = participant.settled || isPayer;

                return (
                  <ParticipantRow key={participant.userId}>
                    <ParticipantInfo>
                      <Avatar
                        src={participant?.photoURL || "/api/placeholder/32/32"}
                        sx={{ width: 32, height: 32 }}
                      />
                      <Box>
                        <Typography variant="body2" fontWeight={500}>
                          {participant?.displayName || participant?.email}
                        </Typography>
                        <Typography
                          variant="caption"
                          color={isSettled ? "success.main" : "text.secondary"}
                        >
                          {isSettled ? "Paid" : "Owes"}
                        </Typography>
                      </Box>
                    </ParticipantInfo>
                    <Typography
                      variant="body2"
                      color={isSettled ? "success.main" : "primary.main"}
                      fontWeight={600}
                    >
                      {formatCurrency(
                        Math.abs(participant.share.original || 0).toFixed(2)
                      )}
                    </Typography>
                  </ParticipantRow>
                );
              })}
            </ParticipantsSection>

            {/* Total Amount Section - Moved to bottom */}
            <Box
              sx={{
                p: 2,
                mt: 2,
                borderTop: `2px dashed ${alpha(theme.palette.divider, 0.1)}`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center", // Add this to vertically center align
                minHeight: 60, // Add fixed height to ensure consistent vertical centering
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Total Amount
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "primary.main",
                  fontWeight: 700,
                }}
              >
                {formatCurrency(parseFloat(bill.amount).toFixed(2))}
              </Typography>
            </Box>

            <Box sx={{ position: "relative" }}>
              <Box className="notch-line">
                <div className="right-notch" />
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                  px: 3,
                  py: 2,
                }}
              >
                {getButtonConfig() && (
                  <Button
                    variant={
                      getButtonConfig().customStyle ? "outlined" : "contained"
                    }
                    onClick={getButtonConfig().action}
                    startIcon={getButtonConfig().icon}
                    sx={{
                      ...getButtonConfig().customStyle,
                      fontWeight: 600,
                      textTransform: "none",
                      borderRadius: "12px",
                      padding: "8px 16px",
                      mt: 2,
                      mx: "auto",
                    }}
                  >
                    {getButtonConfig().label}
                  </Button>
                )}
              </Box>
            </Box>
            <SettlementDrawer
              open={isSettlementOpen}
              onClose={handleCloseSettlement}
              bill={{
                description: bill.description,
                amount: bill.amount,
                splitType: bill.splitType,
                participants: bill.participants,
                status: bill.status || "active",
                paidBy: paidByUser?.userId,
                id: bill.id,
                items: bill.items || [],
              }}
              onBillUpdate={onBillUpdate}
            />
          </CardWrapper>
        )}
      </AnimatePresence>
      <UndoSnackbar
        open={showUndoSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowUndoSnackbar(false)}
        message="Bill deleted"
        action={
          <SnackbarButton
            size="small"
            onClick={handleUndo}
            disabled={isDeleting}
          >
            UNDO
          </SnackbarButton>
        }
      />
      <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Delete Bill</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to delete this bill? This action cannot be
            undone.
          </Typography>
          {deleteError && (
            <Alert
              severity="error"
              sx={{
                mb: 2,
                backgroundColor: (theme) =>
                  alpha(theme.palette.error.main, 0.1),
              }}
            >
              {deleteError}
            </Alert>
          )}
          <Box
            sx={{
              backgroundColor: alpha(theme.palette.background.paper, 0.4),
              p: 2,
              borderRadius: 1,
              mb: 2,
            }}
          >
            <Typography variant="subtitle2" color="text.secondary">
              Bill Details:
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {bill.description}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Amount: {formatCurrency(bill.amount)}
            </Typography>
          </Box>
          {bill.status === "completed" && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Completed bills cannot be deleted
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <DialogButton onClick={handleDeleteCancel} disabled={isDeleting}>
            Cancel
          </DialogButton>
          <DialogButton
            variant="delete"
            onClick={handleDeleteConfirm}
            disabled={isDeleting}
            startIcon={
              isDeleting ? <CircularProgress size={20} /> : <DeleteIcon />
            }
          >
            {isDeleting ? "Deleting..." : "Delete Bill"}
          </DialogButton>
        </DialogActions>
      </DeleteDialog>
    </>
  );
};

export default BillCard;
