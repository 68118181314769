import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Typography,
    alpha,
    Chip,
  } from "@mui/material";
  import {
    History as HistoryIcon,
  } from "@mui/icons-material";
  import UserPresence from "./UserPresence";
const RecentUsers = ({
  recentUsers,
  showRecent,
  hasAttemptedRecentLoad,
  handleRecentUserSelect,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (recentUsers.length > 0) {
      setShouldRender(true);
      setTimeout(() => setIsVisible(true), 50);
    } else {
      setIsVisible(false);
      const timer = setTimeout(() => setShouldRender(false), 300);
      return () => clearTimeout(timer);
    }
  }, [recentUsers]);

  if (!showRecent || !hasAttemptedRecentLoad || !shouldRender) return null;

  return (
    <Box
      sx={{
        mt: 2,
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(5px)",
        transition: "opacity 0.3s ease, transform 0.3s ease",
      }}
    >
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          mb: 1,
        }}
      >
        <HistoryIcon fontSize="small" />
        Recent
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        {recentUsers.map((recentUser) => (
          <Chip
            key={recentUser.userId}
            avatar={
              <Box sx={{ position: "relative" }}>
                <Avatar
                  src={recentUser.photoURL || "/api/placeholder/24/24"}
                  sx={{ width: 24, height: 24 }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: -2,
                    right: -2,
                    transform: "scale(0.8)",
                    zIndex: 1,
                  }}
                >
                  <UserPresence userId={recentUser.userId} />
                </Box>
              </Box>
            }
            label={
              <Typography noWrap sx={{ maxWidth: "150px" }}>
                {recentUser.displayName || recentUser.email}
              </Typography>
            }
            onClick={() => handleRecentUserSelect(recentUser)}
            sx={{
              maxWidth: "200px",
              backgroundColor: (theme) =>
                alpha(theme.palette.secondary.main, 0.1),
              border: (theme) =>
                `1px solid ${alpha(theme.palette.secondary.main, 0.3)}`,
              "& .MuiChip-label": {
                display: "block",
                textOverflow: "ellipsis",
              },
              "&:hover": {
                backgroundColor: (theme) =>
                  alpha(theme.palette.secondary.main, 0.2),
              },
            }}
            clickable
          />
        ))}
      </Box>
    </Box>
  );
};
export default RecentUsers;
