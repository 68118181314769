export const SUPPORTED_CURRENCIES = {
  USD: { symbol: "$", name: "US Dollar", locale: "en-US" },
  EUR: { symbol: "€", name: "Euro", locale: "de-DE" },
  GBP: { symbol: "£", name: "British Pound", locale: "en-GB" },
  JPY: { symbol: "¥", name: "Japanese Yen", locale: "ja-JP" },
  AUD: { symbol: "A$", name: "Australian Dollar", locale: "en-AU" },
  CAD: { symbol: "C$", name: "Canadian Dollar", locale: "en-CA" },
  INR: { symbol: "₹", name: "Indian Rupee", locale: "en-IN" },
  CNY: { symbol: "¥", name: "Chinese Yuan", locale: "zh-CN" },
  // Add more currencies as needed
};

/**
 * Formats a number as currency based on the specified currency code
 * @param {number} amount - The amount to format
 * @param {string} currencyCode - The ISO currency code (e.g., 'USD')
 * @param {Object} options - Additional formatting options
 * @returns {string} Formatted currency string
 */
export const formatCurrency = (amount, currencyCode = "USD", options = {}) => {
  if (typeof amount !== "number" || isNaN(amount)) {
    amount = 0;
  }

  const currency =
    SUPPORTED_CURRENCIES[currencyCode] || SUPPORTED_CURRENCIES.USD;
  const { locale } = currency;

  try {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: options.minimumFractionDigits ?? 2,
      maximumFractionDigits: options.maximumFractionDigits ?? 2,
      ...options,
    }).format(amount);
  } catch (error) {
    console.error("Currency formatting error:", error);
    // Fallback to basic formatting with USD
    return `$${amount.toFixed(2)}`;
  }
};

/**
 * Gets the currency symbol for a given currency code
 * @param {string} currencyCode - The ISO currency code
 * @returns {string} Currency symbol
 */
export const getCurrencySymbol = (currencyCode = "USD") => {
  return SUPPORTED_CURRENCIES[currencyCode]?.symbol || "$";
};

/**
 * Validates if a given currency code is supported
 * @param {string} currencyCode - The ISO currency code to validate
 * @returns {boolean} Whether the currency is supported
 */
export const isValidCurrency = (currencyCode) => {
  return Object.keys(SUPPORTED_CURRENCIES).includes(currencyCode);
};

/**
 * Gets user's currency based on their locale, defaulting to USD if not supported
 * @returns {string} The ISO currency code
 */
export const getDefaultCurrencyByLocale = () => {
  try {
    const locale = navigator.language;
    const currency = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: "USD",
    }).resolvedOptions().currency;

    return isValidCurrency(currency) ? currency : "USD";
  } catch {
    return "USD";
  }
};
